import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useInView } from "react-intersection-observer";
import BlockRevealAnimation from "react-block-reveal-animation";
import { Fade, Slide } from "react-awesome-reveal";

import bgProfile01 from "../images/bgProfile01.png";
import bgProfile02 from "../images/bgProfile02.png";
import SPbgprofile01 from "../images/SPbgprofile01.png";
import item01 from "../images/item01.png";
import pina from "../images/pina.png";
import oka from "../images/oka.png";
import nagisa from "../images/nagisa.png";
import milky from "../images/milky.png";
import natsuki from "../images/natsuki.png";
import sara from "../images/sara.png";
import yuka from "../images/yuka.png";
import yuki from "../images/yuki.png";
import azuki from "../images/azuki.png";
import kaede from "../images/kaede.png";
import box_oka from "../images/box_oka.png";
import box_nagisa from "../images/box_nagisa.png";
import box_azuki from "../images/box_azuki.png";
import box_yuki from "../images/box_yuki.png";
import box_kaede from "../images/box_kaede.png";
import box_milky from "../images/box_milky.png";
import box_pino from "../images/box_pino.png";
import box_sara from "../images/box_sara.png";
import box_natsuki from "../images/box_natsuki.png";
import box_yuka from "../images/box_yuka.png";
// 文字画像
import letter06 from "../images/letter06.png";
import letter07 from "../images/letter07.png";
import letter18 from "../images/letter18.png";
import letter19 from "../images/letter19.png";
import letter20 from "../images/letter20.png";
// アイコン画像
import i_heart_orange from "../images/i_heart_orange.png";
import i_heart_orange2 from "../images/i_heart_orange2.png";
import i_heart_orange3 from "../images/i_heart_orange3.png";
import i_star_orange from "../images/i_star_orange.png";
import i_star_orange2 from "../images/i_star_orange2.png";
import i_star_orange3 from "../images/i_star_orange3.png";

const CharacterPage = ({
  scrollCharacterRef,
  bgColor,
  setBgColor,
  useWindowWidth,
}) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const windowWidth = useWindowWidth();

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slideToShow: 1,
    slideToScroll: 1,
    autoPlay: true,
    focusOnSelect: true,
  };

  const SPsettings = {
    dots: false,
    infinite: true,
    slideToShow: 1,
    slideToScroll: 1,
  };

  const SPsmallSettings = {
    dots: false,
    slideToShow: 3,
    slideToScroll: 1,
  };
  const { ref, inView, entry } = useInView({
    /* Optional options */
    rootMargin: "-100px", // ref要素が現れてから10px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
    threshold: 0,
  });

  const handleSlideClick = (index) => {
    slider1.current.slickGoTo(index);
  };

  if (windowWidth > 496) {
    return (
      <section
        id="CharacterPage"
        className={`w-full CharacterPage ${
          bgColor === "CharacterPage" ? "CharaBg" : ""
        }`}
        ref={ref}
      >
        <div className="w-full" ref={scrollCharacterRef}>
          {inView && (
            <>
              <div className="container relative">
                <div className="title delaney-regular">
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <text x="0%" y="95%">
                      Character
                    </text>
                  </svg>
                </div>
                <Fade
                  duration="2000"
                  delay="10"
                  triggerOnce={true}
                  className="item01 absolute fit-content"
                >
                  <img src={item01} alt="" className="w-full letter_anime" />
                </Fade>
                <Fade
                  duration="1600"
                  delay="0"
                  triggerOnce={true}
                  className="letter06 absolute fit-content"
                >
                  <img src={letter06} alt="" className="w-full letter_anime" />
                </Fade>
                <Fade
                  duration="1800"
                  delay="5"
                  triggerOnce={true}
                  className="letter07 absolute fit-content"
                >
                  <img src={letter07} alt="" className="w-full letter_anime" />
                </Fade>
                {/*  */}
              </div>
              <div className="slickArea">
                {/* スリック箇所 */}
                <Slider
                  {...settings}
                  // {...appendDots}
                  asNavFor={nav2}
                  ref={slider1}
                >
                  {/* 春波 桜花 */}
                  <div className="profile">
                    <Fade
                      duration="500"
                      delay="100"
                      triggerOnce={true}
                      className="flex align-center justify-center w-90"
                    >
                      <div className="relative flex align-center justify-center">
                        <img src={bgProfile01} alt="" className="slickCard" />
                        <Fade
                          duration="1800"
                          delay="320"
                          triggerOnce={true}
                          className="i23 absolute fit-content"
                        >
                          <img
                            src={i_heart_orange}
                            alt=""
                            className="w-full letter_anime"
                          />
                        </Fade>
                        <Fade
                          duration="1800"
                          delay="320"
                          triggerOnce={true}
                          className="letter19 absolute fit-content"
                        >
                          <img
                            src={letter19}
                            alt=""
                            className="letter_anime2"
                          />
                        </Fade>
                        <Fade
                          duration="1800"
                          delay="320"
                          triggerOnce={true}
                          className="i24 absolute fit-content"
                        >
                          <img
                            src={i_heart_orange2}
                            alt=""
                            className="w-full letter_anime"
                          />
                        </Fade>
                        <Fade
                          duration="1800"
                          delay="320"
                          triggerOnce={true}
                          className="i25 absolute fit-content"
                        >
                          <img
                            src={i_star_orange}
                            alt=""
                            className="w-full letter_anime"
                          />
                        </Fade>
                        {/* cute */}
                        <Fade
                          duration="1500"
                          delay="320"
                          triggerOnce={true}
                          className="letter18 absolute fit-content"
                        >
                          <img
                            src={letter18}
                            alt=""
                            className="w-20 letter_anime"
                          />
                        </Fade>
                        <Fade
                          duration="1800"
                          delay="320"
                          triggerOnce={true}
                          className="i26 absolute fit-content"
                        >
                          <img
                            src={i_star_orange2}
                            alt=""
                            className="w-full letter_anime"
                          />
                        </Fade>
                        <Fade
                          duration="1800"
                          delay="320"
                          triggerOnce={true}
                          className="i27 absolute fit-content"
                        >
                          <img
                            src={i_star_orange3}
                            alt=""
                            className="w-full letter_anime"
                          />
                        </Fade>
                        <Fade
                          duration="1800"
                          delay="320"
                          triggerOnce={true}
                          className="i28 absolute fit-content"
                        >
                          <img
                            src={i_heart_orange3}
                            alt=""
                            className="w-full letter_anime"
                          />
                        </Fade>
                      </div>
                    </Fade>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <Fade duration="1000" delay="200" triggerOnce={true}>
                          <img src={oka} alt="" className="character" />
                        </Fade>
                        {/* キャラクター紹介部分 */}
                        <div className="front absolute">
                          <div className="textArea flex column">
                            <div className="flex column gap-12">
                              <span className="firstArea">
                                <span>
                                  <BlockRevealAnimation
                                    delay={3.2}
                                    duration={1}
                                    color="#f47100"
                                  >
                                    <span className="manrope characterText">
                                      <span>春波 桜花</span>
                                    </span>
                                  </BlockRevealAnimation>
                                </span>
                              </span>
                              <span className="secondArea">
                                <BlockRevealAnimation
                                  delay={3.3}
                                  duration={1.2}
                                  color="#f47100"
                                >
                                  <span className="delaney-regular characterText">
                                    <span>Oka Harunami</span>
                                  </span>
                                </BlockRevealAnimation>
                              </span>
                            </div>
                            <span className="catchCopy">
                              <BlockRevealAnimation
                                delay={3.3}
                                duration={1.3}
                                color="#f47100"
                              >
                                <span className="manrope nonBottomLine">
                                  
                                </span>
                              </BlockRevealAnimation>
                            </span>
                            <BlockRevealAnimation
                              delay={3.4}
                              duration={1.1}
                              color="#f47100"
                            >
                              <div className="thirdArea characterText">
                                <span>
                                  <span className="manrope">
                                    優等生で正義感が強く、自分に厳しく人に甘い女の子。<br/>
                                    厳格な母が昔アイドルだったことを父から教わり、興味本位でオーディションに参加するきっかけを見つけ、Fanfareの門を叩く。
                                  </span>
                                </span>
                              </div>
                            </BlockRevealAnimation>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                    <div className="verticalName delaney-regular">
                      <Fade duration="1500" delay="320" triggerOnce={true}>
                        <span>Oka Harunami</span>
                      </Fade>
                    </div>
                  </div>
                  {/* 白月 凪咲 */}
                  <div className="profile">
                    <div className="flex align-center justify-center w-90">
                      <img src={bgProfile02} alt="" className="slickCard" />
                    </div>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <img src={nagisa} alt="" className="character" />
                        {/* キャラクター紹介部分 */}
                        <div className="front absolute">
                          <div className="textArea flex column">
                            <div className="flex column gap-12">
                              <span className="firstArea">
                                <span>
                                  <span className="manrope characterText">
                                    <span>白月 凪咲</span>
                                  </span>
                                </span>
                              </span>
                              <div className="secondArea">
                                <span className="delaney-regular characterText">
                                  <span>Nagisa Shiratsuki</span>
                                </span>
                              </div>
                            </div>
                            <span className="catchCopy">
                              <span className="manrope nonBottomLine">
                                
                              </span>
                            </span>
                            <div className="thirdArea characterText">
                              <span className="manrope">
                                あまり表情を表に出すことが得意ではなく、そんな自分を変えたくて業界に入った。<br/>
                                歌がとても得意なのだが人前で歌ったことはほとんど無い。たまに不器用な一面も見せる。
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter19 absolute fit-content"
                      >
                        <img
                          src={letter19}
                          alt=""
                          className="w-full letter_anime2"
                        />
                      </Fade>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter18 absolute fit-content"
                      >
                        <img
                          src={letter18}
                          alt=""
                          className="w-20 letter_anime"
                        />
                      </Fade>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                    <div className="verticalName delaney-regular">
                      <span>Nagisa Shiratsuki</span>
                    </div>
                  </div>
                  {/* 御代田 あずき */}
                  <div className="profile">
                    <div className="flex align-center justify-center w-90">
                      <img src={bgProfile02} alt="" className="slickCard" />
                    </div>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <img src={azuki} alt="" className="character" />
                        {/* キャラクター紹介部分 */}
                        <div className="front absolute">
                          <div className="textArea flex column">
                            <div className="flex column gap-12">
                              <span className="firstArea">
                                <span>
                                  <span className="manrope characterText">
                                    <span>御代田 あずき</span>
                                  </span>
                                </span>
                              </span>
                              <div className="secondArea">
                                <span className="delaney-regular characterText">
                                  <span>Azuki Miyota</span>
                                </span>
                              </div>
                            </div>
                            <span className="catchCopy">
                              <span className="manrope nonBottomLine">
                                
                              </span>
                            </span>
                            <div className="thirdArea characterText">
                              <span className="manrope">
                                親の転勤をきっかけに都会に引っ越してきた田舎娘。<br/>
                                ゆったりとした性格で愛犬のポポとオーディションに挑み、場を和ませた。
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter19 absolute fit-content"
                      >
                        <img
                          src={letter19}
                          alt=""
                          className="w-full letter_anime2"
                        />
                      </Fade>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter18 absolute fit-content"
                      >
                        <img
                          src={letter18}
                          alt=""
                          className="w-20 letter_anime"
                        />
                      </Fade>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                    <div className="verticalName delaney-regular">
                      <span>Azuki Miyota</span>
                    </div>
                  </div>
                  {/* 橘 優姫 */}
                  <div className="profile">
                    <div className="flex align-center justify-center w-90">
                      <img src={bgProfile02} alt="" className="slickCard" />
                    </div>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <img src={yuki} alt="" className="character" />
                        {/* キャラクター紹介部分 */}
                        <div className="front absolute">
                          <div className="textArea flex column">
                            <div className="flex column gap-12">
                              <span className="firstArea">
                                <span>
                                  <span className="manrope characterText">
                                    <span>橘 優姫</span>
                                  </span>
                                </span>
                              </span>
                              <div className="secondArea">
                                <span className="delaney-regular characterText">
                                  <span>Yuki Tachibana</span>
                                </span>
                              </div>
                            </div>
                            <span className="catchCopy">
                              <span className="manrope nonBottomLine">
                                
                              </span>
                            </span>
                            <div className="thirdArea characterText">
                              <span className="manrope">
                                身長が高く中性的な容姿をしており、たまに見せる男らしい性格から「王子様」と呼ばれることも。<br/>
                                しかし歌声は非常に女性らしく、コンクールで優勝するほどの腕前を持つ。
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter19 absolute fit-content"
                      >
                        <img
                          src={letter19}
                          alt=""
                          className="w-full letter_anime2"
                        />
                      </Fade>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter18 absolute fit-content"
                      >
                        <img
                          src={letter18}
                          alt=""
                          className="w-20 letter_anime"
                        />
                      </Fade>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                    <div className="verticalName delaney-regular">
                      <span>Yuki Tachibana</span>
                    </div>
                  </div>
                  {/* 久喜宮楓 */}
                  <div className="profile">
                    <div className="flex align-center justify-center w-90">
                      <img src={bgProfile02} alt="" className="slickCard" />
                    </div>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <img src={kaede} alt="" className="character" />
                        {/* キャラクター紹介部分 */}
                        <div className="front absolute">
                          <div className="textArea flex column">
                            <div className="flex column gap-12">
                              <span className="firstArea">
                                <span>
                                  <span className="manrope characterText">
                                    <span>久喜宮楓</span>
                                  </span>
                                </span>
                              </span>
                              <div className="secondArea">
                                <span className="delaney-regular characterText">
                                  <span>Kaede Kugumiya</span>
                                </span>
                              </div>
                            </div>
                            <span className="catchCopy">
                              <span className="manrope nonBottomLine">
                                
                              </span>
                            </span>
                            <div className="thirdArea characterText">
                              <span className="manrope">
                                良家のお嬢様。友人に勝手に推薦されFanfareに応募するも、審査を難なく突破する。
                                コンビニやスーパーに行ったことがなく、一般常識から外れて、たまに空気が読めないことも。
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter19 absolute fit-content"
                      >
                        <img
                          src={letter19}
                          alt=""
                          className="w-full letter_anime2"
                        />
                      </Fade>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter18 absolute fit-content"
                      >
                        <img
                          src={letter18}
                          alt=""
                          className="w-20 letter_anime"
                        />
                      </Fade>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                    <div className="verticalName delaney-regular">
                      <span>Kaede Kugumiya</span>
                    </div>
                  </div>
                  {/* ミルキー */}
                  <div className="profile">
                    <div className="flex align-center justify-center w-90">
                      <img src={bgProfile02} alt="" className="slickCard" />
                    </div>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <img src={milky} alt="" className="character" />
                        {/* キャラクター紹介部分 */}
                        <div className="front absolute">
                          <div className="textArea flex column">
                            <div className="flex column gap-12">
                              <span className="firstArea">
                                <span>
                                  <span className="manrope characterText">
                                    <span>ミルキー</span>
                                  </span>
                                </span>
                              </span>
                              <div className="secondArea">
                                <span className="delaney-regular characterText">
                                  <span>Milky</span>
                                </span>
                              </div>
                            </div>
                            <span className="catchCopy">
                              <span className="manrope nonBottomLine">
                                
                              </span>
                            </span>
                            <div className="thirdArea characterText">
                              <span className="manrope">
                                計算高いぶりっ子で、人前に出ることが好きで業界の道へ進んできた。
                                その反面、ダンスはまったくできないが、プライドが高いためそれを認めずにいる。
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter19 absolute fit-content"
                      >
                        <img
                          src={letter19}
                          alt=""
                          className="w-full letter_anime2"
                        />
                      </Fade>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter18 absolute fit-content"
                      >
                        <img
                          src={letter18}
                          alt=""
                          className="w-20 letter_anime"
                        />
                      </Fade>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                    <div className="verticalName delaney-regular">
                      <span>Milky</span>
                    </div>
                  </div>
                  {/* 栗坂 ひな */}
                  <div className="profile">
                    <div className="flex align-center justify-center w-90">
                      <img src={bgProfile02} alt="" className="slickCard" />
                    </div>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <img src={pina} alt="" className="character" />
                        {/* キャラクター紹介部分 */}
                        <div className="front absolute">
                          <div className="textArea flex column">
                            <div className="flex column gap-12">
                              <span className="firstArea">
                                <span>
                                  <span className="manrope characterText">
                                    <span>栗坂 ひな</span>
                                  </span>
                                </span>
                              </span>
                              <div className="secondArea">
                                <span className="delaney-regular characterText">
                                  <span>Hina Kurisaka</span>
                                </span>
                              </div>
                            </div>
                            <span className="catchCopy">
                              <span className="manrope nonBottomLine">
                                
                              </span>
                            </span>
                            <div className="thirdArea characterText">
                              <span className="manrope">
                                たまたま紛れ込んだオーディション会場で類まれないセンスを発揮し、合格。
                                実は天才プログラマーで、人懐っこく、どんな人とも仲良くなれる。
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter19 absolute fit-content"
                      >
                        <img
                          src={letter19}
                          alt=""
                          className="w-full letter_anime2"
                        />
                      </Fade>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter18 absolute fit-content"
                      >
                        <img
                          src={letter18}
                          alt=""
                          className="w-20 letter_anime"
                        />
                      </Fade>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                    <div className="verticalName delaney-regular">
                      <span>Hina Kurisaka</span>
                    </div>
                  </div>
                  {/* サラ・フローレン */}
                  <div className="profile">
                    <div className="flex align-center justify-center w-90">
                      <img src={bgProfile02} alt="" className="slickCard" />
                    </div>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <img src={sara} alt="" className="character" />
                        {/* キャラクター紹介部分 */}
                        <div className="front absolute">
                          <div className="textArea flex column">
                            <div className="flex column gap-12">
                              <span className="firstArea">
                                <span>
                                  <span className="manrope characterText">
                                    <span>サラ・フローレン</span>
                                  </span>
                                </span>
                              </span>
                              <div className="secondArea">
                                <span className="delaney-regular characterText">
                                  <span>Sara Floren</span>
                                </span>
                              </div>
                            </div>
                            <span className="catchCopy">
                              <span className="manrope nonBottomLine">
                                
                              </span>
                            </span>
                            <div className="thirdArea characterText">
                              <span className="manrope">
                                アメリカと日本人のハーフだが、日本生まれのため英語が話せないことがコンプレックス。
                                責任感が強く、努力は隠れてコソコソとやる一面を持つ。
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter19 absolute fit-content"
                      >
                        <img
                          src={letter19}
                          alt=""
                          className="w-full letter_anime2"
                        />
                      </Fade>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter18 absolute fit-content"
                      >
                        <img
                          src={letter18}
                          alt=""
                          className="w-20 letter_anime"
                        />
                      </Fade>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                    <div className="verticalName delaney-regular">
                      <span>Sara Floren</span>
                    </div>
                  </div>
                  {/* 日山 夏希 */}
                  <div className="profile">
                    <div className="flex align-center justify-center w-90">
                      <img src={bgProfile02} alt="" className="slickCard" />
                    </div>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <img src={natsuki} alt="" className="character" />
                        {/* キャラクター紹介部分 */}
                        <div className="front absolute">
                          <div className="textArea flex column">
                            <div className="flex column gap-12">
                              <span className="firstArea">
                                <span>
                                  <span className="manrope characterText">
                                    <span>日山 夏希</span>
                                  </span>
                                </span>
                              </span>
                              <div className="secondArea">
                                <span className="delaney-regular characterText">
                                  <span>Natsuki Hiyama</span>
                                </span>
                              </div>
                            </div>
                            <span className="catchCopy">
                              <span className="manrope nonBottomLine">
                                
                              </span>
                            </span>
                            <div className="thirdArea characterText">
                              <span className="manrope">
                                スポーツ万能、勉強は苦手。
                                スイーツに目がなくパティシエを目指してバイトをしている。
                                Fanfareではムードメーカー的存在。
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter19 absolute fit-content"
                      >
                        <img
                          src={letter19}
                          alt=""
                          className="w-full letter_anime2"
                        />
                      </Fade>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter18 absolute fit-content"
                      >
                        <img
                          src={letter18}
                          alt=""
                          className="w-20 letter_anime"
                        />
                      </Fade>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                    <div className="verticalName delaney-regular">
                      <span>Natsuki Hiyama</span>
                    </div>
                  </div>
                  {/* 藍染 憂香 */}
                  <div className="profile">
                    <div className="flex align-center justify-center w-90">
                      <img src={bgProfile02} alt="" className="slickCard" />
                    </div>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <img src={yuka} alt="" className="character" />
                        {/* キャラクター紹介部分 */}
                        <div className="front absolute">
                          <div className="textArea flex column">
                            <div className="flex column gap-12">
                              <span className="firstArea">
                                <span>
                                  <span className="manrope characterText">
                                    <span>藍染 憂香</span>
                                  </span>
                                </span>
                              </span>
                              <div className="secondArea">
                                <span className="delaney-regular characterText">
                                  <span>Yuka Aizome</span>
                                </span>
                              </div>
                            </div>
                            <span className="catchCopy">
                              <span className="manrope nonBottomLine">
                                
                              </span>
                            </span>
                            <div className="thirdArea characterText">
                              <span className="manrope">
                              根っからのインドア派で、推し活以外ほとんど家からでることがなかったが、推しのアイドルに振り向いてほしくて業界入り。<br/>
                              その推しが桜花の知り合いだと知り、ジェラシーを燃やす。
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter19 absolute fit-content"
                      >
                        <img
                          src={letter19}
                          alt=""
                          className="w-full letter_anime2"
                        />
                      </Fade>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter18 absolute fit-content"
                      >
                        <img
                          src={letter18}
                          alt=""
                          className="w-20 letter_anime"
                        />
                      </Fade>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                    <div className="verticalName delaney-regular">
                      <span>Yuka Aizome</span>
                    </div>
                  </div>
                </Slider>
              </div>
              <Slider
                asNavFor={nav1}
                ref={slider2}
                swipeToSlide={true}
                focusOnSelect={true}
              >
                <div className="characterMiniPics">
                  <div className="appendDot flex align-center justify-between w-full h-full gap-12">
                    <Fade duration="1300" delay="400" triggerOnce={true}>
                      {/* 春波 桜花 */}
                      <div
                        className="one relative flex justify-center w-full"
                        onClick={() => handleSlideClick(0)}
                      >
                        <img src={box_oka} alt="" className="chara" />
                        <span className="slickSelectName">
                          <span className="manrope characterMiniText">
                            <span>春波 桜花</span>
                          </span>
                        </span>
                      </div>
                      {/* 白月 凪咲 */}
                      <div
                        className="one relative flex justify-center w-full"
                        onClick={() => handleSlideClick(1)}
                      >
                        <img src={box_nagisa} alt="" className="chara" />
                        <span className="slickSelectName">
                          <span className="manrope characterMiniText">
                            <span>白月 凪咲</span>
                          </span>
                        </span>
                      </div>
                      {/* 御代田 あずき */}
                      <div
                        className="one relative flex justify-center w-full"
                        onClick={() => handleSlideClick(2)}
                      >
                        <img src={box_azuki} alt="" className="chara" />
                        <span className="slickSelectName">
                          <span className="manrope characterMiniText">
                            <span>御代田 あずき</span>
                          </span>
                        </span>
                      </div>
                      {/* 橘 優姫 */}
                      <div
                        className="one relative flex justify-center w-full"
                        onClick={() => handleSlideClick(3)}
                      >
                        <img src={box_yuki} alt="" className="chara" />
                        <span className="slickSelectName">
                          <span className="manrope characterMiniText">
                            <span>橘 優姫</span>
                          </span>
                        </span>
                      </div>
                      {/* 久喜宮楓 */}
                      <div
                        className="one relative flex justify-center w-full"
                        onClick={() => handleSlideClick(4)}
                      >
                        <img src={box_kaede} alt="" className="chara" />
                        <span className="slickSelectName">
                          <span className="manrope characterMiniText">
                            <span>久喜宮楓</span>
                          </span>
                        </span>
                      </div>
                      {/* ミルキー */}
                      <div
                        className="one relative flex justify-center w-full"
                        onClick={() => handleSlideClick(5)}
                      >
                        <img src={box_milky} alt="" className="chara" />
                        <span className="slickSelectName">
                          <span className="manrope characterMiniText">
                            <span>ミルキー</span>
                          </span>
                        </span>
                      </div>
                      {/* 栗坂 ひな */}
                      <div
                        className="one relative flex justify-center w-full"
                        onClick={() => handleSlideClick(6)}
                      >
                        <img src={box_pino} alt="" className="chara" />
                        <span className="slickSelectName">
                          <span className="manrope characterMiniText">
                            <span>栗坂 ひな</span>
                          </span>
                        </span>
                      </div>
                      {/* サラ・フローレン */}
                      <div
                        className="one relative flex justify-center w-full"
                        onClick={() => handleSlideClick(7)}
                      >
                        <img src={box_sara} alt="" className="chara" />
                        <span className="slickSelectName">
                          <span className="manrope characterMiniText salaTextBox flex align-center justyify-center">
                            <span>サラ・フローレン</span>
                          </span>
                        </span>
                      </div>
                      {/* 日山 夏希 */}
                      <div
                        className="one relative flex justify-center w-full"
                        onClick={() => handleSlideClick(8)}
                      >
                        <img src={box_natsuki} alt="" className="chara" />
                        <span className="slickSelectName">
                          <span className="manrope characterMiniText">
                            <span>日山 夏希</span>
                          </span>
                        </span>
                      </div>
                      {/* 藍染 憂香 */}
                      <div
                        className="one relative flex justify-center w-full"
                        onClick={() => handleSlideClick(9)}
                      >
                        <img src={box_yuka} alt="" className="chara" />
                        <span className="slickSelectName">
                          <span className="manrope characterMiniText">
                            <span>藍染 憂香</span>
                          </span>
                        </span>
                      </div>
                    </Fade>
                  </div>
                </div>
              </Slider>
            </>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section
        className={`SPCharacterPage ${
          bgColor === "CharacterPage" ? "CharaBg" : ""
        }`}
        ref={ref}
      >
        <div className="w-full" ref={scrollCharacterRef}>
          {inView && (
            <>
              <div className="relative">
                <div className="title delaney-regular">
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <text x="0%" y="95%">
                      Character
                    </text>
                  </svg>
                </div>
                <Fade
                  duration="2000"
                  delay="10"
                  triggerOnce={true}
                  className="item01 absolute fit-content"
                >
                  <img src={item01} alt="" className="w-full letter_anime" />
                </Fade>
                {/* <Fade
                  duration="1600"
                  delay="0"
                  triggerOnce={true}
                  className="letter06 absolute fit-content"
                >
                  <img src={letter06} alt="" className="w-full letter_anime" />
                </Fade>
                <Fade
                  duration="1800"
                  delay="5"
                  triggerOnce={true}
                  className="letter07 absolute fit-content"
                >
                  <img src={letter07} alt="" className="w-full letter_anime" />
                </Fade> */}
              </div>
              <div className="slickArea">
                {/* スリック箇所 */}
                <Slider {...SPsettings} asNavFor={nav2} ref={slider1}>
                  {/* 春波 桜花 */}
                  <div className="SPProfile h-full">
                    <div className="imageContent relative h-50">
                      <div className="mainPic absolute">
                        <div className="flex align-center justify-center">
                          <img src={oka} alt="" className="w-60" />
                        </div>
                      </div>
                      <div className="flex align-center justify-center h-full">
                        <img src={SPbgprofile01} alt="" className="w-90" />
                      </div>
                    </div>
                    <div className="textContent flex column align-center justify-center gap2vw h-50">
                      <div className="upper flex align-end justify-start gap2vw w-full">
                        <div className="surround one">
                          <span>
                            <span>春波 桜花</span>
                          </span>
                        </div>
                        <div className="surround two delaney-regular">
                          <span>
                            <span>Oka Harunami</span>
                          </span>
                        </div>
                      </div>
                      <div className="downer flex column">
                        <div className="surround2 w-60">
                          <span>
                            
                          </span>
                        </div>
                        <div className="surround3">
                          <span>
                            <span>
                              それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 白月 凪咲 */}
                  <div className="SPProfile h-full">
                    <div className="imageContent relative h-50">
                      <div className="mainPic absolute">
                        <div className="flex align-center justify-center">
                          <img src={nagisa} alt="" className="w-60" />
                        </div>
                      </div>
                      <div className="flex align-center justify-center h-full">
                        <img src={SPbgprofile01} alt="" className="w-90" />
                      </div>
                    </div>
                    <div className="textContent flex column align-center justify-center gap2vw h-50">
                      <div className="upper flex align-end justify-start gap2vw w-full">
                        <div className="surround one">
                          <span>
                            <span>白月 凪咲</span>
                          </span>
                        </div>
                        <div className="surround two delaney-regular">
                          <span>
                            <span>Nagisa Shiratsuki</span>
                          </span>
                        </div>
                      </div>
                      <div className="downer flex column">
                        <div className="surround2 w-60">
                          <span>
                            
                          </span>
                        </div>
                        <div className="surround3">
                          <span>
                            <span>
                              それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 御代田 あずき */}
                  <div className="SPProfile h-full">
                    <div className="imageContent relative h-50">
                      <div className="mainPic absolute">
                        <div className="flex align-center justify-center">
                          <img src={azuki} alt="" className="w-60" />
                        </div>
                      </div>
                      <div className="flex align-center justify-center h-full">
                        <img src={SPbgprofile01} alt="" className="w-90" />
                      </div>
                    </div>
                    <div className="textContent flex column align-center justify-center gap2vw h-50">
                      <div className="upper flex align-end justify-start gap2vw w-full">
                        <div className="surround one">
                          <span>
                            <span>御代田 あずき</span>
                          </span>
                        </div>
                        <div className="surround two delaney-regular">
                          <span>
                            <span>Azuki Miyota</span>
                          </span>
                        </div>
                      </div>
                      <div className="downer flex column">
                        <div className="surround2 w-60">
                          <span>
                            
                          </span>
                        </div>
                        <div className="surround3">
                          <span>
                            <span>
                              それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 橘 優姫 */}
                  <div className="SPProfile h-full">
                    <div className="imageContent relative h-50">
                      <div className="mainPic absolute">
                        <div className="flex align-center justify-center">
                          <img src={yuki} alt="" className="w-60" />
                        </div>
                      </div>
                      <div className="flex align-center justify-center h-full">
                        <img src={SPbgprofile01} alt="" className="w-90" />
                      </div>
                    </div>
                    <div className="textContent flex column align-center justify-center gap2vw h-50">
                      <div className="upper flex align-end justify-start gap2vw w-full">
                        <div className="surround one">
                          <span>
                            <span>橘 優姫</span>
                          </span>
                        </div>
                        <div className="surround two delaney-regular">
                          <span>
                            <span>Yuki Tachibana</span>
                          </span>
                        </div>
                      </div>
                      <div className="downer flex column">
                        <div className="surround2 w-60">
                          <span>
                            
                          </span>
                        </div>
                        <div className="surround3">
                          <span>
                            <span>
                              それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 久喜宮楓 */}
                  <div className="SPProfile h-full">
                    <div className="imageContent relative h-50">
                      <div className="mainPic absolute">
                        <div className="flex align-center justify-center">
                          <img src={kaede} alt="" className="w-60" />
                        </div>
                      </div>
                      <div className="flex align-center justify-center h-full">
                        <img src={SPbgprofile01} alt="" className="w-90" />
                      </div>
                    </div>
                    <div className="textContent flex column align-center justify-center gap2vw h-50">
                      <div className="upper flex align-end justify-start gap2vw w-full">
                        <div className="surround one">
                          <span>
                            <span>久喜宮楓</span>
                          </span>
                        </div>
                        <div className="surround two delaney-regular">
                          <span>
                            <span>Kaede Kugumiya</span>
                          </span>
                        </div>
                      </div>
                      <div className="downer flex column">
                        <div className="surround2 w-60">
                          <span>
                            
                          </span>
                        </div>
                        <div className="surround3">
                          <span>
                            <span>
                              それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ミルキー */}
                  <div className="SPProfile h-full">
                    <div className="imageContent relative h-50">
                      <div className="mainPic absolute">
                        <div className="flex align-center justify-center">
                          <img src={milky} alt="" className="w-60" />
                        </div>
                      </div>
                      <div className="flex align-center justify-center h-full">
                        <img src={SPbgprofile01} alt="" className="w-90" />
                      </div>
                    </div>
                    <div className="textContent flex column align-center justify-center gap2vw h-50">
                      <div className="upper flex align-end justify-start gap2vw w-full">
                        <div className="surround one">
                          <span>
                            <span>ミルキー</span>
                          </span>
                        </div>
                        <div className="surround two delaney-regular">
                          <span>
                            <span>Milky</span>
                          </span>
                        </div>
                      </div>
                      <div className="downer flex column">
                        <div className="surround2 w-60">
                          <span>
                            
                          </span>
                        </div>
                        <div className="surround3">
                          <span>
                            <span>
                              それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 栗坂 ひな */}
                  <div className="SPProfile h-full">
                    <div className="imageContent relative h-50">
                      <div className="mainPic absolute">
                        <div className="flex align-center justify-center">
                          <img src={pina} alt="" className="w-60" />
                        </div>
                      </div>
                      <div className="flex align-center justify-center h-full">
                        <img src={SPbgprofile01} alt="" className="w-90" />
                      </div>
                    </div>
                    <div className="textContent flex column align-center justify-center gap2vw h-50">
                      <div className="upper flex align-end justify-start gap2vw w-full">
                        <div className="surround one">
                          <span>
                            <span>栗坂 ひな</span>
                          </span>
                        </div>
                        <div className="surround two delaney-regular">
                          <span>
                            <span>Hina Kurisaka</span>
                          </span>
                        </div>
                      </div>
                      <div className="downer flex column">
                        <div className="surround2 w-60">
                          <span>
                            
                          </span>
                        </div>
                        <div className="surround3">
                          <span>
                            <span>
                              それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* サラ・フローレン */}
                  <div className="SPProfile h-full">
                    <div className="imageContent relative h-50">
                      <div className="mainPic absolute">
                        <div className="flex align-center justify-center">
                          <img src={sara} alt="" className="w-60" />
                        </div>
                      </div>
                      <div className="flex align-center justify-center h-full">
                        <img src={SPbgprofile01} alt="" className="w-90" />
                      </div>
                    </div>
                    <div className="textContent flex column align-center justify-center gap2vw h-50">
                      <div className="upper flex align-end justify-start gap2vw w-full">
                        <div className="surround one SPsara">
                          <span>
                            <span>サラ・フローレン</span>
                          </span>
                        </div>
                        <div className="surround two delaney-regular">
                          <span>
                            <span>Sara Floren</span>
                          </span>
                        </div>
                      </div>
                      <div className="downer flex column">
                        <div className="surround2 w-60">
                          <span>
                            
                          </span>
                        </div>
                        <div className="surround3">
                          <span>
                            <span>
                              それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 日山 夏希 */}
                  <div className="SPProfile h-full">
                    <div className="imageContent relative h-50">
                      <div className="mainPic absolute">
                        <div className="flex align-center justify-center">
                          <img src={natsuki} alt="" className="w-60" />
                        </div>
                      </div>
                      <div className="flex align-center justify-center h-full">
                        <img src={SPbgprofile01} alt="" className="w-90" />
                      </div>
                    </div>
                    <div className="textContent flex column align-center justify-center gap2vw h-50">
                      <div className="upper flex align-end justify-start gap2vw w-full">
                        <div className="surround one">
                          <span>
                            <span>日山 夏希</span>
                          </span>
                        </div>
                        <div className="surround two delaney-regular">
                          <span>
                            <span>Natsuki Hiyama</span>
                          </span>
                        </div>
                      </div>
                      <div className="downer flex column">
                        <div className="surround2 w-60">
                          <span>
                            
                          </span>
                        </div>
                        <div className="surround3">
                          <span>
                            <span>
                              それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 藍染 憂香 */}
                  <div className="SPProfile h-full">
                    <div className="imageContent relative h-50">
                      <div className="mainPic absolute">
                        <div className="flex align-center justify-center">
                          <img src={yuka} alt="" className="w-60" />
                        </div>
                      </div>
                      <div className="flex align-center justify-center h-full">
                        <img src={SPbgprofile01} alt="" className="w-90" />
                      </div>
                    </div>
                    <div className="textContent flex column align-center justify-center gap2vw h-50">
                      <div className="upper flex align-end justify-start gap2vw w-full">
                        <div className="surround one">
                          <span>
                            <span>藍染 憂香</span>
                          </span>
                        </div>
                        <div className="surround two delaney-regular">
                          <span>
                            <span>Yuka Aizome</span>
                          </span>
                        </div>
                      </div>
                      <div className="downer flex column">
                        <div className="surround2 w-60">
                          <span>
                            
                          </span>
                        </div>
                        <div className="surround3">
                          <span>
                            <span>
                              それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
                {/* <Slider {...settings}>
                  <div className="profile">
                    <Fade
                      duration="1200"
                      delay="200"
                      triggerOnce={true}
                      className="flex align-center justify-center w-90"
                    >
                      <img src={bgProfile01} alt="" className="slickCard" />
                    </Fade>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <Fade duration="2000" delay="320" triggerOnce={true}>
                          <img
                            src={pina}
                            alt=""
                            className="character"
                          />
                        </Fade>
                        <div className="front absolute">
                          <div className="textArea flex column">
                            <span className="firstArea">
                              <span>
                                <BlockRevealAnimation
                                  delay={5.7}
                                  duration={1}
                                  color="#f47100"
                                >
                                  <span className="manrope characterText">
                                    <span>春波 桜花</span>
                                  </span>
                                </BlockRevealAnimation>
                              </span>
                            </span>
                            <span className="secondArea">
                              <BlockRevealAnimation
                                delay={5.8}
                                duration={1.2}
                                color="#f47100"
                              >
                                <span className="delaney-regular characterText">
                                  <span>Oka Harunami</span>
                                </span>
                              </BlockRevealAnimation>
                            </span>
                            <span className="catchCopy">
                              <BlockRevealAnimation
                                delay={5.8}
                                duration={1.3}
                                color="#f47100"
                              >
                                <span className="manrope nonBottomLine">
                                  
                                </span>
                              </BlockRevealAnimation>
                            </span>
                            <BlockRevealAnimation
                              delay={5.9}
                              duration={1.1}
                              color="#f47100"
                            >
                              <div className="thirdArea characterText">
                                <span>
                                  <span className="manrope">
                                    それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                                  </span>
                                </span>
                              </div>
                            </BlockRevealAnimation>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter19 absolute fit-content"
                      >
                        <img
                          src={letter19}
                          alt=""
                          className="w-full letter_anime2"
                        />
                      </Fade>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter18 absolute fit-content"
                      >
                        <img
                          src={letter18}
                          alt=""
                          className="w-full letter_anime"
                        />
                      </Fade>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                    <div className="verticalName delaney-regular absolute">
                      <Fade duration="1500" delay="320" triggerOnce={true}>
                        <span>Oka Harunami</span>
                      </Fade>
                    </div>
                  </div>
                  <div className="profile">
                    <Fade
                      duration="1200"
                      delay="200"
                      triggerOnce={true}
                      className="flex align-center justify-center w-90"
                    >
                      <img src={bgProfile02} alt="" className="slickCard" />
                    </Fade>
                    <div className="mainProfile absolute w-full h-full">
                      <div className="slickContent">
                        <img src={pina} alt="" className="character" />
                        <div className="front absolute">
                          <div className="textArea">
                            <span className="firstArea">
                              <span>
                                <span className="manrope characterText">
                                  <span>春波 桜花</span>
                                </span>
                              </span>
                            </span>
                            <div className="secondArea">
                              <span className="delaney-regular characterText">
                                <span>Oka Harunami</span>
                              </span>
                            </div>
                            <span className="catchCopy">
                              <span className="manrope nonBottomLine">
                                
                              </span>
                            </span>
                            <div className="thirdArea characterText">
                              <span className="manrope">
                                それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはいうたたんます。学校に決するでのはもし場合をもう
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter19 absolute fit-content"
                      >
                        <img
                          src={letter19}
                          alt=""
                          className="w-full letter_anime2"
                        />
                      </Fade>
                      <Fade
                        duration="0"
                        delay="0"
                        triggerOnce={true}
                        className="letter18 absolute fit-content"
                      >
                        <img
                          src={letter18}
                          alt=""
                          className="w-full letter_anime"
                        />
                      </Fade>
                      <Fade
                        duration="1500"
                        delay="320"
                        triggerOnce={true}
                        className="letter20 absolute fit-content"
                      >
                        <img
                          src={letter20}
                          alt=""
                          className="w-full letter_anime3"
                        />
                      </Fade>
                    </div>
                  </div>
                </Slider> */}
              </div>
              <div className="characterMiniPics"
              style={{display:'none'}}>
                <Slider {...SPsmallSettings}>
                  <div
                    className="one relative flex justify-center"
                    onClick={() => handleSlideClick(0)}
                  >
                    <img src={box_oka} alt="" className="chara" />
                    <span className="slickSelectName">
                      <span className="manrope characterMiniText">
                        <span>春波 桜花</span>
                      </span>
                    </span>
                  </div>
                  <div
                    className="one relative flex justify-center w-full"
                    onClick={() => handleSlideClick(1)}
                  >
                    <img src={box_nagisa} alt="" className="chara" />
                    <span className="slickSelectName">
                      <span className="manrope characterMiniText">
                        <span>白月 凪咲</span>
                      </span>
                    </span>
                  </div>
                  <div
                    className="one relative flex justify-center w-full"
                    onClick={() => handleSlideClick(2)}
                  >
                    <img src={box_azuki} alt="" className="chara" />
                    <span className="slickSelectName">
                      <span className="manrope characterMiniText">
                        <span>御代田 あずき</span>
                      </span>
                    </span>
                  </div>
                  <div
                    className="one relative flex justify-center w-full"
                    onClick={() => handleSlideClick(3)}
                  >
                    <img src={box_yuki} alt="" className="chara" />
                    <span className="slickSelectName">
                      <span className="manrope characterMiniText">
                        <span>橘 優姫</span>
                      </span>
                    </span>
                  </div>
                  <div
                    className="one relative flex justify-center w-full"
                    onClick={() => handleSlideClick(4)}
                  >
                    <img src={box_kaede} alt="" className="chara" />
                    <span className="slickSelectName">
                      <span className="manrope characterMiniText">
                        <span>久喜宮楓</span>
                      </span>
                    </span>
                  </div>
                  <div
                    className="one relative flex justify-center w-full"
                    onClick={() => handleSlideClick(5)}
                  >
                    <img src={box_milky} alt="" className="chara" />
                    <span className="slickSelectName">
                      <span className="manrope characterMiniText">
                        <span>ミルキー</span>
                      </span>
                    </span>
                  </div>
                  <div
                    className="one relative flex justify-center w-full"
                    onClick={() => handleSlideClick(6)}
                  >
                    <img src={box_pino} alt="" className="chara" />
                    <span className="slickSelectName">
                      <span className="manrope characterMiniText">
                        <span>栗坂 ひな</span>
                      </span>
                    </span>
                  </div>
                  <div
                    className="one relative flex justify-center w-full"
                    onClick={() => handleSlideClick(7)}
                  >
                    <img src={box_sara} alt="" className="chara" />
                    <span className="slickSelectName">
                      <span className="manrope characterMiniText">
                        <span>サラ・フローレン</span>
                      </span>
                    </span>
                  </div>
                  <div
                    className="one relative flex justify-center w-full"
                    onClick={() => handleSlideClick(8)}
                  >
                    <img src={box_natsuki} alt="" className="chara" />
                    <span className="slickSelectName">
                      <span className="manrope characterMiniText">
                        <span>日山 夏希</span>
                      </span>
                    </span>
                  </div>
                  <div
                    className="one relative flex justify-center w-full"
                    onClick={() => handleSlideClick(9)}
                  >
                    <img src={box_yuka} alt="" className="chara" />
                    <span className="slickSelectName">
                      <span className="manrope characterMiniText">
                        <span>藍染 憂香</span>
                      </span>
                    </span>
                  </div>
                </Slider>
              </div>
            </>
          )}
        </div>
      </section>
    );
  }
};

export default CharacterPage;
