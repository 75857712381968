import { Link as Scroll } from "react-scroll";
import selfiesHeader from "../images/Selfies_header.png";

const Header = (props) => {
  const windowWidth = props.useWindowWidth();
  return (
    <section
      className={`Header flex align-center justify-center ${
        props.inView ? "invisible" : "appear"
      }`}
      style={(windowWidth < 496 ? { display:'none'} : {})}
    >
      <div className="flex align-center justify-between w-full h-full">
        <a href="/" className="logo">
          <img src={selfiesHeader} alt="Selfies" />
        </a>
        <div className="flex align-center gap-20">
          <nav className="menu manrope flex gap-20">
            <div className="underline uppercase cursor" onClick={props.scrollTop}>
              Top
            </div>
            <div className="underline uppercase cursor" onClick={props.scrollAbout}>
              About
            </div>
            <div className="underline uppercase cursor" onClick={props.scrollMint}>
              Sell
            </div>
            <div className="underline uppercase cursor" onClick={props.scrollCharacter}>
              Character
            </div>
            {/* <div className="underline uppercase cursor" onClick={props.scrollPG}>
              PlayGround
            </div> */}
            <div className="underline uppercase cursor" onClick={props.scrollTeam}>
              Team
            </div>
            <div className="underline uppercase cursor" onClick={props.scrollQA}>
              What's Selfies
            </div>
            <button className="uppercase cursor" disabled>En</button>
          </nav>
          <nav className="social-media flex align-center justify-center">
            <a
              href="https://twitter.com/selfies_NFT"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="22"
                viewBox="0 0 27 22"
              >
                <path
                  className="snsIcons"
                  id="white_background"
                  d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                  transform="translate(-1 -1.497)"
                />
              </svg>
            </a>
            <a href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24"
                height="22"
                viewBox="0 0 16.849 12.751"
                className="discord"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="長方形_4157"
                      data-name="長方形 4157"
                      width="16.849"
                      height="12.751"
                    />
                  </clipPath>
                </defs>
                <g id="Discord-Logo-Color" clip-path="url(#clip-path)">
                  <path
                    id="パス_4740"
                    data-name="パス 4740"
                    d="M14.385,1.485A14.013,14.013,0,0,0,10.926.413a.052.052,0,0,0-.056.026,9.761,9.761,0,0,0-.431.885,12.936,12.936,0,0,0-3.885,0A8.952,8.952,0,0,0,6.118.439.055.055,0,0,0,6.062.413,13.974,13.974,0,0,0,2.6,1.485a.049.049,0,0,0-.023.02A14.341,14.341,0,0,0,.07,11.177a.058.058,0,0,0,.022.04,14.091,14.091,0,0,0,4.243,2.145.055.055,0,0,0,.06-.02,10.071,10.071,0,0,0,.868-1.412.054.054,0,0,0-.029-.075,9.28,9.28,0,0,1-1.326-.632.055.055,0,0,1-.005-.09c.089-.067.178-.136.263-.206a.053.053,0,0,1,.055-.007,10.049,10.049,0,0,0,8.54,0,.052.052,0,0,1,.056.007c.085.07.174.14.264.207a.054.054,0,0,1,0,.09,8.708,8.708,0,0,1-1.326.631.054.054,0,0,0-.029.076,11.308,11.308,0,0,0,.867,1.411.054.054,0,0,0,.06.02,14.044,14.044,0,0,0,4.25-2.145.055.055,0,0,0,.022-.039,14.247,14.247,0,0,0-2.512-9.672A.043.043,0,0,0,14.385,1.485ZM5.678,9.246A1.633,1.633,0,0,1,4.151,7.534,1.624,1.624,0,0,1,5.678,5.821,1.615,1.615,0,0,1,7.205,7.534,1.624,1.624,0,0,1,5.678,9.246Zm5.646,0A1.633,1.633,0,0,1,9.8,7.534a1.624,1.624,0,0,1,1.527-1.713,1.615,1.615,0,0,1,1.527,1.713A1.62,1.62,0,0,1,11.325,9.246Z"
                    transform="translate(0.117 -0.313)"
                  />
                </g>
              </svg>
            </a>
            <a href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24"
                height="22"
                viewBox="0 0 16.846 15.162"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="長方形_4158"
                      data-name="長方形 4158"
                      width="16.846"
                      height="15.162"
                    />
                  </clipPath>
                </defs>
                <g
                  id="グループ_4132"
                  data-name="グループ 4132"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="パス_4741"
                    data-name="パス 4741"
                    d="M3.36,15.6H7.016a.121.121,0,0,0,.1-.053c.048-.073.093-.149.133-.226a6.28,6.28,0,0,0,.7-1.529A7.574,7.574,0,0,0,7.083,9.78a.122.122,0,0,0-.213-.015L3.315,15.326l-.059.093a.121.121,0,0,0,.1.187"
                    transform="translate(-2.254 -6.761)"
                  />
                  <path
                    id="パス_4742"
                    data-name="パス 4742"
                    d="M16.691,9.574l-4.157,1.2a.116.116,0,0,0-.057.035,10.994,10.994,0,0,1-.931.916,1.407,1.407,0,0,1-.915.338h-1.5V10.53h1.2A.125.125,0,0,0,10.4,10.5l.154-.142c.066-.06.145-.133.239-.227.056-.056.118-.117.178-.184s.122-.128.179-.194c.094-.1.184-.206.278-.317a2.648,2.648,0,0,0,.189-.239c.067-.079.133-.167.2-.251.045-.066.1-.133.145-.2s.09-.139.128-.206a3.958,3.958,0,0,0,.3-.573c.043-.094.077-.194.111-.289a2.587,2.587,0,0,0,.083-.293,2.271,2.271,0,0,0,.039-.7,1.287,1.287,0,0,0-.028-.212V6.454a.86.86,0,0,0-.032-.15,3.566,3.566,0,0,0-.229-.678c-.034-.083-.073-.167-.111-.244-.09-.167-.184-.334-.291-.5-.038-.06-.083-.122-.122-.182-.083-.128-.178-.25-.267-.368-.067-.088-.145-.177-.218-.267s-.145-.173-.217-.255c-.261-.295-.535-.562-.779-.789-.045-.045-.094-.09-.145-.133-.19-.173-.362-.323-.507-.439-.073-.056-.133-.111-.189-.156L9.322,2.17a.125.125,0,0,0-.036-.019l-.16-.045V.781A.785.785,0,0,0,8.9.23.761.761,0,0,0,8.353,0a.776.776,0,0,0-.772.78v.893L7.5,1.652,7.283,1.59l-.2-.055-.005,0h0l-1.5-.407a.078.078,0,0,0-.088.114l.24.444c.022.056.055.112.083.173.039.079.079.161.117.244s.067.145.107.223c.077.173.162.355.244.556.056.133.112.267.162.406.135.349.263.723.373,1.106.039.122.067.239.1.361l.015.067c.045.179.084.355.112.534.022.122.043.239.055.357.017.133.034.267.039.4s.017.25.017.372a3.96,3.96,0,0,1-.094.9c-.022.083-.045.171-.073.256-.028.1-.067.2-.105.306-.022.06-.045.122-.073.182a8.839,8.839,0,0,1-.49.979c-.261.462-.524.868-.708,1.129-.025.038-.049.072-.07.1a.125.125,0,0,0,.1.2H7.581v1.535H5.615a1.4,1.4,0,0,1-1.252-.778,1.355,1.355,0,0,1-.141-.762.135.135,0,0,0-.129-.15H.122A.123.123,0,0,0,0,10.5v.081a4.577,4.577,0,0,0,4.57,4.585h7.122c1.707,0,2.471-1.99,3.47-3.381a4.38,4.38,0,0,1,1.609-1.1.126.126,0,0,0,.076-.115v-.88a.123.123,0,0,0-.156-.118"
                    transform="translate(0 -0.001)"
                  />
                </g>
              </svg>
            </a>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default Header;
