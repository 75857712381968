import { useState } from "react";
import { Slide, Roll, Fade } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import BlockRevealAnimation from "react-block-reveal-animation";
import { useInView } from "react-intersection-observer";

import item04 from "../images/item04.png";
import item05 from "../images/item05.png";
import item06 from "../images/item06.png";
import item07 from "../images/item07.png";
import paint01 from "../images/paint01.png";
import paint02 from "../images/paint02.png";
import member01 from "../images/oka.webp";
import member02 from "../images/pina.webp";
import member03 from "../images/yuki.webp";
import member04 from "../images/sara.webp";
import HaruyuImage from "../images/HaruyuImage.png";
import ABCAnimation_TheBattle from "../images/ABCAnimation_TheBattle.png";
import wagmi_records from "../images/wagmi_records.png";
// 文字画像
import letter08 from "../images/letter08.png";
import letter09 from "../images/letter09.png";
// アイコン画像
import i_cat from "../images/i_cat.png";
import i_artist_green from "../images/i_artist_green.png";
import i_face_green from "../images/i_face_green.png";
import i_heart_green from "../images/i_heart_green.png";
import i_heart_green2 from "../images/i_heart_green2.png";
import i_heart_green3 from "../images/i_heart_green3.png";
import i_king from "../images/i_king.png";
import i_square_green from "../images/i_square_green.png";
import i_star_green from "../images/i_star_green.png";
import i_star_green2 from "../images/i_star_green2.png";
import i_star_green3 from "../images/i_star_green3.png";


export const Produce = () => {
  const rollRightAnime = keyframes`
    from {
      transform: translateX(30%) rotate(20deg);
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    to {
      transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
  `;

  return (
    <section className="Produce relative w-full h-full">
      <div className="subtitle delaney-regular">
        <svg xmlns="http://www.w3.org/2000/svg">
          <text x="0%" y="95%">
            Produce
          </text>
        </svg>
      </div>
      <div>
        <Roll
          direction="right"
          duration="1800"
          delay="70"
          triggerOnce={true}
          keyframes={rollRightAnime}
        >
          <div className="imageShadow shadow01 absolute"></div>
        </Roll>
        <Roll
          direction="right"
          duration="1800"
          delay="50"
          triggerOnce={true}
          keyframes={rollRightAnime}
        >
          <div className="box w-full relative">
            <div className="absolute paint01">
              <img src={paint01} alt="" />
            </div>
            <div className="absolute paint02">
              <img src={paint02} alt="" className="paint02" />
            </div>
            <div className="container flex gap-30">
              <div className="leftSide flex align-center">
                <img
                  src={wagmi_records}
                  alt="Wagmi Records"
                  className="wagmi_records"
                />
              </div>
              <div className="rightSide flex column justify-center gap-5">
                <div>
                  <h2 className="uppercase">Wagmi Records</h2>
                </div>
                <div>
                  <img
                    src={ABCAnimation_TheBattle}
                    alt=""
                    className="ABCAnimation_TheBattle"
                  />
                </div>
                {/* <div className="manrope black">
                  <p>
                    それも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて、それほどとはそれも場合もしそのお話しめというのの限りが与えますた。同時に時間で批評料はもしその担任ですたともに措いのでくれなでも中止構わたなて
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </Roll>
      </div>
    </section>
  );
};

export const MiniBox = (props) => {
  return (
    <div className={`miniBox ${props.skew ? "skew1" : "skew2"}`}>
      <div className="miniContainer flex gap-30">
        {props.name &&<>
          <div className="leftSide flex">
            <img src={props.image} alt="" className="member" />
          </div>
          <div className="rightSide flex column justify-center gap-5">
            <div>
              {props.name}
            </div>
            <div>
              <h4 className="black">{props.title}</h4>
            </div>
            <div className="icons">
              <span className="flex align-center gap-12 flex-end">
                {/* <a href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.802"
                    height="22.407"
                    viewBox="0 0 27.802 22.407"
                  >
                    <path
                      id="white_background"
                      d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                      transform="translate(-1 -1.497)"
                      className="fillGreen"
                    />
                  </svg>
                </a>
                <a href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.656"
                    height="16.878"
                    viewBox="0 0 16.656 16.878"
                  >
                    <g
                      id="リンクのフリーアイコン1"
                      transform="matrix(1, -0.017, 0.017, 1, -0.111, 0.286)"
                    >
                      <path
                        id="パス_4689"
                        data-name="パス 4689"
                        d="M207.178,3.669a4.528,4.528,0,0,0-7.651-2.339L197.38,3.477a1.109,1.109,0,0,0,1.568,1.568L201.095,2.9a2.314,2.314,0,1,1,3.271,3.272l-2.147,2.146a1.109,1.109,0,0,0,1.568,1.568l2.147-2.147a4.526,4.526,0,0,0,1.244-4.067Z"
                        transform="translate(-190.67)"
                        fill="#5ab20f"
                      />
                      <path
                        id="パス_4690"
                        data-name="パス 4690"
                        d="M8.313,202.243l-2.147,2.147a2.311,2.311,0,0,1-2.079.635,2.3,2.3,0,0,1-1.193-.635,2.311,2.311,0,0,1-.508-.765,2.309,2.309,0,0,1,.508-2.507l2.147-2.147A1.108,1.108,0,1,0,3.473,197.4l-2.147,2.146a4.531,4.531,0,1,0,6.407,6.407l2.147-2.147a1.108,1.108,0,0,0-1.568-1.567Z"
                        transform="translate(0 -190.69)"
                        fill="#5ab20f"
                      />
                      <path
                        id="パス_4691"
                        data-name="パス 4691"
                        d="M165.458,159.883a1.109,1.109,0,0,0-1.568,0l-4.035,4.035a1.108,1.108,0,0,0,1.568,1.567l4.035-4.035A1.107,1.107,0,0,0,165.458,159.883Z"
                        transform="translate(-154.362 -154.385)"
                        fill="#5ab20f"
                      />
                    </g>
                  </svg>
                </a> */}
              </span>
            </div>
          </div></>
        }
      </div>
    </div>
  );
};

export const Creative = () => {
  const [skew, setSkew] = useState(true);
  const SlideLeftAnime = keyframes`
    0% {
      transform: translate(-50%, 0%) rotate(20deg);
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  `;
  const SlideLeftAnime2 = keyframes`
    0% {
      transform: translate(-50%, 0%) rotate(20deg);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  `;
  const rollRightAnime = keyframes`
    0% {
      transform: translate(-50%, 0%) rotate(20deg);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  `;
  const rollRightAnime2 = keyframes`
    0% {
      transform: translate(-50%, 0%) rotate(20deg);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  `;

  return (
    <section className="Creative w-full h-full">
      <div className="subtitle delaney-regular">
        <svg xmlns="http://www.w3.org/2000/svg">
          <text x="0%" y="95%">
            Creative
          </text>
        </svg>
      </div>
      <div className="creativeContainter flex column">
        <div className="relative">
          <Roll
            direction="right"
            duration="2000"
            delay="0"
            triggerOnce={true}
            keyframes={SlideLeftAnime}
          >
            <div className="imageShadow shadow01 absolute"></div>
          </Roll>
          <Roll
            direction="right"
            duration="2000"
            delay="20"
            triggerOnce={true}
            keyframes={SlideLeftAnime}
          >
            <div className="box flex gap-50">
              <div className="flex align-center leftSide">
                <img
                  src={HaruyuImage}
                  alt="はるユ"
                  className="HaruyuImage w-full"
                />
              </div>
              <div className="rightSide flex column justify-center gap-12 w-full">
                <div className="memberName relative flex align-center justify-between">
                  <h2 className="inline-block">はるユ</h2>
                  <span className="flex align-center gap-20">
                    {/* <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.802"
                        height="22.407"
                        viewBox="0 0 27.802 22.407"
                      >
                        <path
                          id="white_background"
                          d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                          transform="translate(-1 -1.497)"
                          className="fillGreen"
                        />
                      </svg>
                    </a>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.656"
                        height="16.878"
                        viewBox="0 0 16.656 16.878"
                      >
                        <g
                          id="リンクのフリーアイコン1"
                          transform="matrix(1, -0.017, 0.017, 1, -0.111, 0.286)"
                        >
                          <path
                            id="パス_4689"
                            data-name="パス 4689"
                            d="M207.178,3.669a4.528,4.528,0,0,0-7.651-2.339L197.38,3.477a1.109,1.109,0,0,0,1.568,1.568L201.095,2.9a2.314,2.314,0,1,1,3.271,3.272l-2.147,2.146a1.109,1.109,0,0,0,1.568,1.568l2.147-2.147a4.526,4.526,0,0,0,1.244-4.067Z"
                            transform="translate(-190.67)"
                            fill="#5ab20f"
                          />
                          <path
                            id="パス_4690"
                            data-name="パス 4690"
                            d="M8.313,202.243l-2.147,2.147a2.311,2.311,0,0,1-2.079.635,2.3,2.3,0,0,1-1.193-.635,2.311,2.311,0,0,1-.508-.765,2.309,2.309,0,0,1,.508-2.507l2.147-2.147A1.108,1.108,0,1,0,3.473,197.4l-2.147,2.146a4.531,4.531,0,1,0,6.407,6.407l2.147-2.147a1.108,1.108,0,0,0-1.568-1.567Z"
                            transform="translate(0 -190.69)"
                            fill="#5ab20f"
                          />
                          <path
                            id="パス_4691"
                            data-name="パス 4691"
                            d="M165.458,159.883a1.109,1.109,0,0,0-1.568,0l-4.035,4.035a1.108,1.108,0,0,0,1.568,1.567l4.035-4.035A1.107,1.107,0,0,0,165.458,159.883Z"
                            transform="translate(-154.362 -154.385)"
                            fill="#5ab20f"
                          />
                        </g>
                      </svg>
                    </a> */}
                  </span>
                </div>
                <div className="memberBox w-70">
                  <h3>超絶技巧でアイドルたちを描くアーティスト</h3>
                </div>
                <div className="memberText">
                  <p>
                  </p>
                </div>
              </div>
            </div>
          </Roll>
        </div>
        <div className="memberProfile flex gap-12">
          <MiniBox name="BATTLE" title='Founder / プロデューサー' image={member01} />
          <MiniBox name="syou" title='エンジニア' image={member02} />
          <MiniBox name="hagi" title='イラストレーター' image={member03} />
          <MiniBox name="MINUI" title='イラストレーター' image={member04} />
          <MiniBox />
          <MiniBox />
        </div>
      </div>
    </section>
  );
};

const TeamPage = (props) => {
  const [skew, setSkew] = useState(true);
  const windowWidth = props.useWindowWidth();
  const { ref, inView, entry } = useInView({
    /* Optional options */
    rootMargin: "-100px", // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
    threshold: 0,
  });
  const SlideRightAnime = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(-1%);
    opacity: 1;
  }
`;
  const SlideLeftAnime = keyframes`
  0% {
    transform: translate(-50%, 0%) rotate(20deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
  const SlideLeftAnime2 = keyframes`
  0% {
    transform: translate(-50%, 0%) rotate(20deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
  const rollRightAnime = keyframes`
  0% {
    transform: translate(-50%, 0%) rotate(20deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
  const rollRightAnime2 = keyframes`
  0% {
    transform: translate(-50%, 0%) rotate(20deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

  if (windowWidth > 496) {
    return (
      <section
        id="TeamPage"
        className={`relative w-full ${
          props.bgColor === "TeamPage" ? "TeamBg" : ""
        } `}
        ref={props.scrollTeamRef}
      >
        <div ref={ref}>
          {inView && (
            <>
              <div className="title delaney-regular">
                <svg xmlns="http://www.w3.org/2000/svg">
                  <text x="0%" y="95%">
                    Team
                  </text>
                </svg>
              </div>
              <div className="textArea">
                <div className="secondArea">
                  <BlockRevealAnimation
                    delay={0.2}
                    duration={1.1}
                    color="#a0d275"
                  >
                    <span className="manrope teamText">
                      <span>
                      web3での経験豊富なチームと
                      </span>
                    </span>
                  </BlockRevealAnimation>
                </div>
                <div className="secondArea">
                  <BlockRevealAnimation
                    delay={0.3}
                    duration={1}
                    color="#a0d275"
                  >
                    <span className="manrope teamText">
                      <span>
                      朝日放送グループのアニメ製作会社がタッグを組んで盛り上げる！
                      </span>
                    </span>
                  </BlockRevealAnimation>
                </div>
              </div>
            </>
          )}
        </div>
        <Fade
          duration="2000"
          delay="250"
          triggerOnce={true}
          className="item04 absolute fit-content"
        >
          <img src={item04} alt="" className="w-full letter_anime" />
        </Fade>
        <Fade
          duration="1800"
          delay="270"
          triggerOnce={true}
          className="item05 absolute fit-content"
        >
          <img src={item05} alt="" className="w-full letter_anime2" />
        </Fade>
        <Fade
          duration="1900"
          delay="300"
          triggerOnce={true}
          className="item06 absolute fit-content"
        >
          <img src={item06} alt="" className="w-full letter_anime3" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="item07 absolute fit-content"
        >
          <img src={item07} alt="" className="w-full letter_anime" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="letter08 absolute fit-content"
        >
          <img src={letter08} alt="" className="w-full letter_anime3" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="letter09 absolute fit-content"
        >
          <img src={letter09} alt="" className="w-full letter_anime" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="i59 absolute fit-content"
        >
          <img src={i_cat} alt="" className="w-full letter_anime2" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="i29 absolute fit-content"
        >
          <img src={i_artist_green} alt="" className="w-full letter_anime3" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="i30 absolute fit-content"
        >
          <img src={i_face_green} alt="" className="w-full letter_anime3" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="i31 absolute fit-content"
        >
          <img src={i_star_green} alt="" className="w-full letter_anime" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="i32 absolute fit-content"
        >
          <img src={i_star_green2} alt="" className="w-full letter_anime1" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="i33 absolute fit-content"
        >
          <img src={i_star_green3} alt="" className="w-full letter_anime2" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="i34 absolute fit-content"
        >
          <img src={i_king} alt="" className="w-full letter_anime3" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="i35 absolute fit-content"
        >
          <img src={i_heart_green3} alt="" className="w-full letter_anime" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="i36 absolute fit-content"
        >
          <img src={i_square_green} alt="" className="w-full letter_anime2" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="i37 absolute fit-content"
        >
          <img src={i_heart_green2} alt="" className="w-full letter_anime3" />
        </Fade>
        <Fade
          duration="2100"
          delay="300"
          triggerOnce={true}
          className="i38 absolute fit-content"
        >
          <img src={i_heart_green} alt="" className="w-full letter_anime" />
        </Fade>
        <div
          className="teamContainer flex align-center justify-center column gap-30"
          ref={ref}
        >
          {inView && (
            <>
              <Produce />
              <Creative />
            </>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section
        className={`SPTeam SP relative w-full ${
          props.bgColor === "TeamPage" ? "TeamBg" : ""
        }`}
        ref={ref}
      >
        <div className="relative w-full" ref={props.scrollTeamRef}>
          <div className="SPTeamContainer relative w-full">
            {inView && (
              <>
                <div className="title delaney-regular">
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <text x="0%" y="95%">
                      Team
                    </text>
                  </svg>
                </div>
                <div className="textArea">
                  <div className="flex column">
                    <div className="secondArea">
                      <BlockRevealAnimation
                        delay={0.2}
                        duration={1.1}
                        color="#a0d275"
                      >
                        <span className="manrope teamText">
                          <span>web3での経験豊富なチームと</span>
                        </span>
                      </BlockRevealAnimation>
                    </div>
                    <div className="secondArea">
                      <BlockRevealAnimation
                        delay={0.3}
                        duration={1.1}
                        color="#a0d275"
                      >
                        <span className="manrope teamText">
                          <span>国民的アニメ制作スタジオが</span>
                        </span>
                      </BlockRevealAnimation>
                    </div>
                  </div>
                  <div className="flex column">
                    <div className="secondArea">
                      <BlockRevealAnimation
                        delay={0.5}
                        duration={1}
                        color="#a0d275"
                      >
                        <span className="manrope teamText">
                          <span>タッグを組んで盛り上げる！、</span>
                        </span>
                      </BlockRevealAnimation>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="title delaney-regular">
              <svg xmlns="http://www.w3.org/2000/svg">
                <text x="0%" y="95%">
                  Produce
                </text>
              </svg>
            </div>
            {/* <div className="imageShadow shadow01 absolute"></div> */}
            <Slide
              direction="right"
              duration="2000"
              delay="0"
              triggerOnce={true}
              keyframes={SlideRightAnime}
            >
              <div className="box w-full relative">
                {/* <div className="absolute paint01">
                  <img src={paint01} alt="" className="paint01" />
                </div>
                <div className="absolute paint02">
                  <img src={paint02} alt="" className="paint02" />
                </div> */}
                <div className="container flex gap-30 flex column">
                  <div className="leftSide flex align-center justify-center">
                    <img
                      src={wagmi_records}
                      alt="Wagmi Records"
                      className="wagmi_records"
                    />
                  </div>
                  <div className="rightSide flex column align-center justify-center gap5vw">
                    <div>
                      <h2 className="uppercase">Wagmi Records</h2>
                    </div>
                    <div className="flex align-center justify-center w-full">
                      <img
                        src={ABCAnimation_TheBattle}
                        alt=""
                        className="ABCAnimation_TheBattle"
                      />
                    </div>
                    <div className="manrope black">
                      <p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <div className="title delaney-regular">
              <svg xmlns="http://www.w3.org/2000/svg">
                <text x="0%" y="95%">
                  Creative
                </text>
              </svg>
            </div>
            <div className="creativeContainter flex column">
              <div className="container relative">
                <div className="imageShadow shadow01 absolute"></div>
                <Slide
                  direction="right"
                  duration="2000"
                  delay="0"
                  triggerOnce={true}
                  keyframes={SlideLeftAnime}
                >
                  <div className="box flex column gap10vw">
                    <div className="flex align-center leftSide">
                      <img
                        src={HaruyuImage}
                        alt="はるユ"
                        className="HaruyuImage w-full"
                      />
                    </div>
                    <div className="rightSide flex column justify-center gap-12 w-full">
                      <div className="memberName relative flex align-center justify-between">
                        <h2 className="inline-block">はるユ</h2>
                      </div>
                      <div className="memberBox w-90">
                        <h3>超絶技巧でアイドルたちを描く</h3>
                      </div>
                      <div className="memberBox w-50">
                        <h3>アーティスト</h3>
                      </div>
                      {/* <span className="flex align-center gap-20">
                        <a
                          href="https://twitter.com/ha_ru_ha_ru1192"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.802"
                            height="22.407"
                            viewBox="0 0 27.802 22.407"
                          >
                            <path
                              id="white_background"
                              d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                              transform="translate(-1 -1.497)"
                              className="fillGreen"
                            />
                          </svg>
                        </a>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.656"
                            height="16.878"
                            viewBox="0 0 16.656 16.878"
                          >
                            <g
                              id="リンクのフリーアイコン1"
                              transform="matrix(1, -0.017, 0.017, 1, -0.111, 0.286)"
                            >
                              <path
                                id="パス_4689"
                                data-name="パス 4689"
                                d="M207.178,3.669a4.528,4.528,0,0,0-7.651-2.339L197.38,3.477a1.109,1.109,0,0,0,1.568,1.568L201.095,2.9a2.314,2.314,0,1,1,3.271,3.272l-2.147,2.146a1.109,1.109,0,0,0,1.568,1.568l2.147-2.147a4.526,4.526,0,0,0,1.244-4.067Z"
                                transform="translate(-190.67)"
                                fill="#5ab20f"
                              />
                              <path
                                id="パス_4690"
                                data-name="パス 4690"
                                d="M8.313,202.243l-2.147,2.147a2.311,2.311,0,0,1-2.079.635,2.3,2.3,0,0,1-1.193-.635,2.311,2.311,0,0,1-.508-.765,2.309,2.309,0,0,1,.508-2.507l2.147-2.147A1.108,1.108,0,1,0,3.473,197.4l-2.147,2.146a4.531,4.531,0,1,0,6.407,6.407l2.147-2.147a1.108,1.108,0,0,0-1.568-1.567Z"
                                transform="translate(0 -190.69)"
                                fill="#5ab20f"
                              />
                              <path
                                id="パス_4691"
                                data-name="パス 4691"
                                d="M165.458,159.883a1.109,1.109,0,0,0-1.568,0l-4.035,4.035a1.108,1.108,0,0,0,1.568,1.567l4.035-4.035A1.107,1.107,0,0,0,165.458,159.883Z"
                                transform="translate(-154.362 -154.385)"
                                fill="#5ab20f"
                              />
                            </g>
                          </svg>
                        </a>
                      </span> */}
                      <div className="memberText">
                        <p>
                        </p>
                      </div>
                    </div>
                  </div>
                </Slide>
              </div>
            </div>
            <div className="memberProfile flex column gap-12">
              <div className="flex gap2vw">
                <div className="memberContent skew2 flex column gap4vw">
                  <div className="image flex align-center justify-center">
                    <img src={member01} alt="" className="w-full" />
                  </div>
                  <div className="content flex column gap-5">
                    <div className="fonts5vw">
                      <span>BATTLE</span>
                    </div>
                    <div className="black fonts4vw">
                      <span>Founder / プロデューサー</span>
                    </div>
                    <div className="white fonts3vw">
                      <div className="flex column gap-5">
                        {/* <span className="bgGreen w-70">アイドルたちの</span>
                        <span className="bgGreen w-80">敏腕プロデューサー</span> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="sns">
                    <span className="flex align-center flex-end gap-12">
                      <a
                        href="https://twitter.com/ha_ru_ha_ru1192"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.802"
                          height="22.407"
                          viewBox="0 0 27.802 22.407"
                        >
                          <path
                            id="white_background"
                            d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                            transform="translate(-1 -1.497)"
                            className="fillGreen"
                          />
                        </svg>
                      </a>
                      <a href="#" target="_blank" rel="noopener noreferrer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.656"
                          height="16.878"
                          viewBox="0 0 16.656 16.878"
                        >
                          <g
                            id="リンクのフリーアイコン1"
                            transform="matrix(1, -0.017, 0.017, 1, -0.111, 0.286)"
                          >
                            <path
                              id="パス_4689"
                              data-name="パス 4689"
                              d="M207.178,3.669a4.528,4.528,0,0,0-7.651-2.339L197.38,3.477a1.109,1.109,0,0,0,1.568,1.568L201.095,2.9a2.314,2.314,0,1,1,3.271,3.272l-2.147,2.146a1.109,1.109,0,0,0,1.568,1.568l2.147-2.147a4.526,4.526,0,0,0,1.244-4.067Z"
                              transform="translate(-190.67)"
                              fill="#5ab20f"
                            />
                            <path
                              id="パス_4690"
                              data-name="パス 4690"
                              d="M8.313,202.243l-2.147,2.147a2.311,2.311,0,0,1-2.079.635,2.3,2.3,0,0,1-1.193-.635,2.311,2.311,0,0,1-.508-.765,2.309,2.309,0,0,1,.508-2.507l2.147-2.147A1.108,1.108,0,1,0,3.473,197.4l-2.147,2.146a4.531,4.531,0,1,0,6.407,6.407l2.147-2.147a1.108,1.108,0,0,0-1.568-1.567Z"
                              transform="translate(0 -190.69)"
                              fill="#5ab20f"
                            />
                            <path
                              id="パス_4691"
                              data-name="パス 4691"
                              d="M165.458,159.883a1.109,1.109,0,0,0-1.568,0l-4.035,4.035a1.108,1.108,0,0,0,1.568,1.567l4.035-4.035A1.107,1.107,0,0,0,165.458,159.883Z"
                              transform="translate(-154.362 -154.385)"
                              fill="#5ab20f"
                            />
                          </g>
                        </svg>
                      </a>
                    </span>
                  </div> */}
                </div>
                
                <div className="memberContent skew2 flex column gap4vw">
                  <div className="image flex align-center justify-center">
                    <img src={member02} alt="" className="w-full" />
                  </div>
                  <div className="content flex column gap-5">
                    <div className="fonts5vw">
                      <span>syou</span>
                    </div>
                    <div className="black fonts4vw">
                      <span>エンジニア</span>
                    </div>
                    <div className="white fonts3vw">
                      <div className="flex column gap-5">
                        {/* <span className="bgGreen w-70">アイドルたちの</span>
                        <span className="bgGreen w-80">敏腕プロデューサー</span> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="sns">
                    <span className="flex align-center flex-end gap-12">
                      <a
                        href="https://twitter.com/ha_ru_ha_ru1192"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.802"
                          height="22.407"
                          viewBox="0 0 27.802 22.407"
                        >
                          <path
                            id="white_background"
                            d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                            transform="translate(-1 -1.497)"
                            className="fillGreen"
                          />
                        </svg>
                      </a>
                      <a href="#" target="_blank" rel="noopener noreferrer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.656"
                          height="16.878"
                          viewBox="0 0 16.656 16.878"
                        >
                          <g
                            id="リンクのフリーアイコン1"
                            transform="matrix(1, -0.017, 0.017, 1, -0.111, 0.286)"
                          >
                            <path
                              id="パス_4689"
                              data-name="パス 4689"
                              d="M207.178,3.669a4.528,4.528,0,0,0-7.651-2.339L197.38,3.477a1.109,1.109,0,0,0,1.568,1.568L201.095,2.9a2.314,2.314,0,1,1,3.271,3.272l-2.147,2.146a1.109,1.109,0,0,0,1.568,1.568l2.147-2.147a4.526,4.526,0,0,0,1.244-4.067Z"
                              transform="translate(-190.67)"
                              fill="#5ab20f"
                            />
                            <path
                              id="パス_4690"
                              data-name="パス 4690"
                              d="M8.313,202.243l-2.147,2.147a2.311,2.311,0,0,1-2.079.635,2.3,2.3,0,0,1-1.193-.635,2.311,2.311,0,0,1-.508-.765,2.309,2.309,0,0,1,.508-2.507l2.147-2.147A1.108,1.108,0,1,0,3.473,197.4l-2.147,2.146a4.531,4.531,0,1,0,6.407,6.407l2.147-2.147a1.108,1.108,0,0,0-1.568-1.567Z"
                              transform="translate(0 -190.69)"
                              fill="#5ab20f"
                            />
                            <path
                              id="パス_4691"
                              data-name="パス 4691"
                              d="M165.458,159.883a1.109,1.109,0,0,0-1.568,0l-4.035,4.035a1.108,1.108,0,0,0,1.568,1.567l4.035-4.035A1.107,1.107,0,0,0,165.458,159.883Z"
                              transform="translate(-154.362 -154.385)"
                              fill="#5ab20f"
                            />
                          </g>
                        </svg>
                      </a>
                    </span>
                  </div> */}
                </div>
              </div>
              <div className="flex gap2vw">
                <div className="memberContent skew1 flex column gap4vw">
                  <div className="image flex align-center justify-center">
                    <img src={member03} alt="" className="w-full" />
                  </div>
                  <div className="content flex column gap-5">
                    <div className="fonts5vw">
                      <span>hagi</span>
                    </div>
                    <div className="black fonts4vw">
                      <span>イラストレーター</span>
                    </div>
                  </div>
                </div>
                <div className="memberContent skew2 flex column gap4vw">
                  <div className="image flex align-center justify-center">
                    <img src={member04} alt="" className="w-full" />
                  </div>
                  <div className="content flex column gap-5">
                    <div className="fonts5vw">
                      <span>MINUI</span>
                    </div>
                    <div className="black fonts4vw">
                      <span>イラストレーター</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default TeamPage;
