import {
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
} from "react";
import "./App.css";

import AboutPage from "./components/AboutPage";
import CharacterPage from "./components/CharacterPage";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MintPage from "./components/MintPage";
import QAPage from "./components/QAPage";
import PlayGroundPage from "./components/PlayGround";
import TeamPage from "./components/TeamPage";
import TopPage from "./components/TopPage";
import SPHeader from "./components/SPHeader";
import { useInView } from "react-intersection-observer";

function App() {
  // SP版ヘッダーの切り替わり
  const [active, setActive] = useState(false);
  const [visible, setVisible] = useState(true);

  // 各コンポーネントに遷移するための参照のためのref
  const scrollTopRef = useRef(null);
  const scrollAboutRef = useRef(null);
  const scrollMintRef = useRef(null);
  const scrollCharacterRef = useRef(null);
  const scrollPlayGroundRef = useRef(null);
  const scrollTeamRef = useRef(null);
  const scrollQAPageRef = useRef(null);
  const scrollFooterRef = useRef(null);

  // スクロールによって背景色変更のstate
  const [bgColor, setBgColor] = useState("TopPage");
  const styleMap = {
    "TopPage": { backgroundColor: "#FEEBF2" },
    "AboutPage": { backgroundColor: "#FEEBF2" },
    "MintPage": { backgroundColor: "#D9E2FF" },
    "CharacterPage": { backgroundColor: "#FFE2D6" },
    "PlayGroundPage": { backgroundColor: "#FFE2D6" },
    "TeamPage": { backgroundColor: "#A0D275" },
    "QAPage": { backgroundColor: "#BCEDFA" },
    "Footer": { backgroundColor: "#FF7EAB" },
  };
  const style = styleMap[bgColor] || {};

  // refを定義
  const ref = useRef();
  // TopPageの"SCROLL DOWN"の文字をスクロールしたときに消すためのstate
  const scrollRemove = () => {
    setVisible(false);
  };
  const PositionUp = () => {
    // 画面のスクロール量(px)をstateに保持
    // setScrollPosition(ref.current.scrollTop);

    // 1pxでもスクロールしたらscrollRemoveが実行
    if (ref.current.scrollTop > 0) {
      scrollRemove();
      // スクロール量をコンソールに出力
      // console.log(ref.current.scrollTop);
    }
  };

  // スクロールしたらPositionUpが実行
  useEffect(() => {
    const div = ref.current;
    div.addEventListener("scroll", PositionUp);
    return () => {
      div.removeEventListener("scroll", PositionUp);
    };
  }, []);

  useEffect(() => {
    const div = ref.current;
    const scroll = ref.current.scrollTop;

    if (scrollTopRef.current !== null) {
      // TopPage.jsのtopのY座標を取得
      const rectTop = scrollTopRef.current.getBoundingClientRect();
      div.addEventListener("scroll", () => {
        if (scroll >= rectTop.top) {
          setBgColor("TopPage");
        }
      });
    }
    if (scrollAboutRef.current !== null) {
      const rectAbout = scrollAboutRef.current.getBoundingClientRect();
      div.addEventListener("scroll", () => {
        if (scroll > rectAbout.top) {
          setBgColor("AboutPage");
        }
      });
    }
    if (scrollMintRef.current !== null) {
      const rectMint = scrollMintRef.current.getBoundingClientRect();
      div.addEventListener("scroll", () => {
        if (scroll > rectMint.top) {
          setBgColor("MintPage");
        }
      });
    }
    if (scrollCharacterRef.current !== null) {
      const rectChara = scrollCharacterRef.current.getBoundingClientRect();
      div.addEventListener("scroll", () => {
        if (scroll > rectChara.top) {
          setBgColor("CharacterPage");
        }
      });
    }
    if (scrollTeamRef.current !== null) {
      const rectTeam = scrollTeamRef.current.getBoundingClientRect();
      div.addEventListener("scroll", () => {
        if (scroll > rectTeam.top) {
          setBgColor("TeamPage");
        }
      });
    }
    if (scrollQAPageRef.current !== null) {
      const rectQA = scrollQAPageRef.current.getBoundingClientRect();
      div.addEventListener("scroll", () => {
        if (scroll > rectQA.top) {
          setBgColor("QAPage");
        }
      });
    }
    if (scrollFooterRef.current !== null) {
      const rectFooter = scrollFooterRef.current.getBoundingClientRect();
      div.addEventListener("scroll", () => {
        if (scroll > rectFooter.top) {
          setBgColor("Footer");
        }
      });
    }
  }, []);

  const useWindowWidth = () => {
    const [widthSize, setWidthSize] = useState(0);

    useLayoutEffect(() => {
      const updateSize = () => {
        setWidthSize(window.innerWidth);
      };

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }, []);

    return widthSize;
  };

  // 各コンポーネントに遷移する関数
  const opt = {
    behavior: "smooth",
    block: "start",
  };

  const scrollTop = () => {
    scrollTopRef?.current?.scrollIntoView({
      ...opt,
    });
  };
  const scrollAbout = () => {
    scrollAboutRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const scrollMint = () => {
    scrollMintRef?.current?.scrollIntoView({
      ...opt,
    });
  };
  const scrollCharacter = () => {
    scrollCharacterRef?.current?.scrollIntoView({
      ...opt,
    });
  };
  const scrollPG = () => {
    scrollPlayGroundRef?.current?.scrollIntoView({
      ...opt,
    });
  };
  const scrollTeam = () => {
    scrollTeamRef?.current?.scrollIntoView({
      ...opt,
    });
  };
  const scrollQA = () => {
    scrollQAPageRef?.current?.scrollIntoView({
      ...opt,
    });
  };

  // メインビジュアルが見えなくなったらヘッダーが現れる
  const headerBlur = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  return (
    <div className="App" ref={ref}>
      {active ? (
        <SPHeader
          useWindowWidth={useWindowWidth}
          scrollAbout={scrollAbout}
          scrollMint={scrollMint}
          scrollCharacter={scrollCharacter}
          scrollPG={scrollPG}
          scrollTeam={scrollTeam}
          scrollQA={scrollQA}
          active={active}
          setActive={setActive}
        />
      ) : (
        <div>
          <div className="" ref={headerBlur.ref}>
            <TopPage
              useWindowWidth={useWindowWidth}
              scrollTopRef={scrollTopRef}
              scrollTop={scrollTop}
              scrollAbout={scrollAbout}
              scrollMint={scrollMint}
              scrollCharacter={scrollCharacter}
              scrollPG={scrollPG}
              scrollTeam={scrollTeam}
              scrollQA={scrollQA}
              bgColor={bgColor}
              active={active}
              setActive={setActive}
              visible={visible}
              />
          </div>
          <div className="">
            <Header
              scrollTop={scrollTop}
              scrollAbout={scrollAbout}
              scrollMint={scrollMint}
              scrollCharacter={scrollCharacter}
              scrollPG={scrollPG}
              scrollTeam={scrollTeam}
              scrollQA={scrollQA}
              inView={headerBlur.inView}
              useWindowWidth={useWindowWidth}
              />
            <AboutPage
              useWindowWidth={useWindowWidth}
              scrollAboutRef={scrollAboutRef}
              bgColor={bgColor}
            />
            <MintPage
              useWindowWidth={useWindowWidth}
              scrollMintRef={scrollMintRef}
              bgColor={bgColor}
            />
            <CharacterPage
              useWindowWidth={useWindowWidth}
              scrollCharacterRef={scrollCharacterRef}
              bgColor={bgColor}
              setBgColor={setBgColor}
            />
            {/* <PlayGroundPage
            useWindowWidth={useWindowWidth}
            scrollPlayGroundRef={scrollPlayGroundRef}
            bgColor={bgColor}
          /> */}
            <TeamPage
              useWindowWidth={useWindowWidth}
              scrollTeamRef={scrollTeamRef}
              bgColor={bgColor}
            />
            <QAPage
              useWindowWidth={useWindowWidth}
              scrollQAPageRef={scrollQAPageRef}
              bgColor={bgColor}
            />

            <Footer
              useWindowWidth={useWindowWidth}
              scrollFooterRef={scrollFooterRef}
              scrollTop={scrollTop}
              scrollAbout={scrollAbout}
              scrollMint={scrollMint}
              scrollCharacter={scrollCharacter}
              scrollPG={scrollPG}
              scrollTeam={scrollTeam}
              scrollQA={scrollQA}
              bgColor={bgColor}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
