import { Fade, Roll } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { useInView } from "react-intersection-observer";
import BlockRevealAnimation from "react-block-reveal-animation";

import about01 from "../images/about01.png";
import about02 from "../images/about02.png";
import about03 from "../images/about03.png";
import about04 from "../images/about04.png";
import about05 from "../images/about05.png";
import about06 from "../images/about06.png";
import letter01 from "../images/letter01.png";
import letter02 from "../images/letter02.png";
import letter03 from "../images/letter03.png";
import i_hiyoko from "../images/i_hiyoko.png";
import i_star from "../images/i_star.png";
import i_star2 from "../images/i_star2.png";
import i_star3 from "../images/i_star3.png";
import i_heart from "../images/i_heart.png";
import i_heart2 from "../images/i_heart2.png";

const AboutPage = ({ useWindowWidth, scrollAboutRef, bgColor }) => {
  const windowWidth = useWindowWidth();
  const { ref, inView, entry } = useInView({
    /* Optional options */
    rootMargin: "-50px",
    triggerOnce: true, // 最初の一度だけ実行
    threshold: 0, // ref要素が見えてからすぐ
  });

  const rollRightAnime = keyframes`
  from {
    transform: translateX(100%) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  `;
  const rollLeftAnime = keyframes`
  from {
    transform: translate(-200%, 100%) rotate(-90deg);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    transform: translateX(0%) rotate(0deg);
    opacity: 1;
  }
`;
  if (windowWidth > 496) {
    return (
      <section id="AboutPage" className={`relative w-full ${bgColor === "AboutPage" ? "AboutBg" : ""}`} ref={ref}>
        <div ref={scrollAboutRef} className="relative w-full">
          {inView && (
            <>
              <div className="bgPic w-full h-full absolute">
                <div className="relative w-full h-full">
                  <Roll
                    direction="right"
                    duration="2000"
                    delay="50"
                    triggerOnce={true}
                    keyframes={rollRightAnime}
                    className="absolute w-full h-full zIndex1"
                  >
                    <img
                      src={about04}
                      alt=""
                      className="letter_anime aboutPic absolute about04"
                    />
                  </Roll>
                  <Roll
                    direction="right"
                    duration="2400"
                    delay="0"
                    triggerOnce={true}
                    keyframes={rollLeftAnime}
                    className="absolute w-full h-full zIndex5"
                  >
                    <img
                      src={about01}
                      alt=""
                      className="letter_anime2 aboutPic absolute about01"
                    />
                  </Roll>
                  <Roll
                    direction="right"
                    duration="2500"
                    delay="50"
                    triggerOnce={true}
                    keyframes={rollRightAnime}
                    className="absolute w-full h-full zIndex3"
                  >
                    <img
                      src={about06}
                      alt=""
                      className="letter_anime3 aboutPic absolute about06"
                    />
                  </Roll>
                  <Roll
                    direction="right"
                    duration="2600"
                    delay="0"
                    triggerOnce={true}
                    keyframes={rollLeftAnime}
                    className="absolute w-full h-full"
                  >
                    <img
                      src={about05}
                      alt=""
                      className="letter_anime3 aboutPic absolute about05"
                    />
                  </Roll>
                  <Roll
                    direction="right"
                    duration="3000"
                    delay="0"
                    triggerOnce={true}
                    keyframes={rollRightAnime}
                    className="absolute w-full h-full zIndex3"
                  >
                    <img
                      src={about02}
                      alt=""
                      className="letter_anime aboutPic absolute about02"
                    />
                  </Roll>
                  <Roll
                    direction="right"
                    duration="2000"
                    delay="0"
                    triggerOnce={true}
                    keyframes={rollLeftAnime}
                    className="absolute w-full h-full zIndex6"
                  >
                    <img
                      src={about03}
                      alt=""
                      className="letter_anime2 aboutPic absolute about03"
                    />
                  </Roll>
                  <Fade
                    duration="2000"
                    delay="250"
                    triggerOnce={true}
                    className="letter01 absolute fit-content"
                  >
                    <img src={letter01} alt="" className="w-full" />
                  </Fade>
                  <Fade
                    duration="2000"
                    delay="250"
                    triggerOnce={true}
                    className="letter02 absolute fit-content"
                  >
                    <img src={letter02} alt="" className="w-full" />
                  </Fade>
                  <Fade
                    duration="2000"
                    delay="250"
                    triggerOnce={true}
                    className="letter03 absolute fit-content"
                  >
                    <img src={letter03} alt="" className="w-full" />
                  </Fade>
                  <Fade
                    duration="2000"
                    delay="250"
                    triggerOnce={true}
                    className="i9 absolute fit-content"
                  >
                    <img src={i_hiyoko} alt="" className="w-full" />
                  </Fade>
                  <Fade
                    duration="2000"
                    delay="250"
                    triggerOnce={true}
                    className="i10 absolute fit-content"
                  >
                    <img src={i_star} alt="" className="w-full" />
                  </Fade>
                  <Fade
                    duration="2000"
                    delay="250"
                    triggerOnce={true}
                    className="i11 absolute fit-content"
                  >
                    <img src={i_star2} alt="" className="w-full" />
                  </Fade>
                  <Fade
                    duration="2000"
                    delay="250"
                    triggerOnce={true}
                    className="i12 absolute fit-content"
                  >
                    <img src={i_heart} alt="" className="w-full" />
                  </Fade>
                  <Fade
                    duration="2000"
                    delay="250"
                    triggerOnce={true}
                    className="i13 absolute fit-content"
                  >
                    <img src={i_star3} alt="" className="w-full" />
                  </Fade>
                  <Fade
                    duration="2000"
                    delay="250"
                    triggerOnce={true}
                    className="i14 absolute fit-content"
                  >
                    <img src={i_heart2} alt="" className="w-full" />
                  </Fade>
                  <Fade
                    duration="2000"
                    delay="250"
                    triggerOnce={true}
                    className="i15 absolute fit-content"
                  >
                    <img src={i_heart} alt="" className="w-full" />
                  </Fade>
                </div>
              </div>
              <div className="title delaney-regular">
                <svg xmlns="http://www.w3.org/2000/svg">
                  <text x="0%" y="95%">
                    About
                  </text>
                </svg>
              </div>
              <div className="front absolute">
                {
                  <div className="textArea">
                    <div className="firstArea">
                      <div>
                        <BlockRevealAnimation
                          delay={2.1}
                          duration={0.7}
                          color="#f53c7c"
                        >
                          <span className="manrope aboutText">
                            <span>アイドルたちの、</span>
                          </span>
                        </BlockRevealAnimation>
                      </div>
                      <div>
                        <BlockRevealAnimation
                          delay={2.3}
                          duration={0.6}
                          color="#f53c7c"
                        >
                          <span className="manrope aboutText">
                            <span>素顔に迫る。</span>
                          </span>
                        </BlockRevealAnimation>
                      </div>
                    </div>
                    <div className="secondArea">
                      <BlockRevealAnimation
                        delay={2}
                        duration={1.8}
                        color="#f53c7c"
                      >
                        <span className="delaney-regular aboutText">
                          <span>The daily lives of idols.</span>
                        </span>
                      </BlockRevealAnimation>
                    </div>
                    <div className="thirdArea">
                      <BlockRevealAnimation
                        delay={2}
                        duration={1.9}
                        color="#f53c7c"
                      >
                        <div className="aboutText">
                          <span className="manrope">
                          まだ無名のガールズポップユニット「Fanfare」は一流のアーティストを目指して毎日一生懸命仕事をします。<br/>
                          歌やダンスレッスンはもちろんですが、様々な場所や姿で仕事をし、有名になるための努力は怠りません。<br/><br/>
                          彼女たちはその活躍を世界に知らせるために、自撮り写真をNFTにすることにしました。<br/><br/>
                          あなたはプロデューサーとして好きなアイドルたちを応援してあげてください。<br/>
                          全員の心が一つになったとき、彼女たちは今よりも幅広く活躍でき、プロデューサーたちを楽しませることができるでしょう
                          </span>
                        </div>
                      </BlockRevealAnimation>
                    </div>
                  </div>
                }
              </div>
            </>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section className="SPAboutPage" ref={ref}>
        <div className="relative w-full h-full" ref={scrollAboutRef}>
          {inView && (
            <>
              <div className="bgPic w-full h-full absolute">
                <div className="relative w-full h-full">
                  <Roll
                    direction="right"
                    duration="2000"
                    delay="50"
                    triggerOnce={true}
                    keyframes={rollRightAnime}
                    className="absolute w-full h-full zIndex1"
                  >
                    <img
                      src={about04}
                      alt=""
                      className="letter_anime aboutPic absolute about04"
                    />
                  </Roll>
                  <Roll
                    direction="right"
                    duration="2400"
                    delay="0"
                    triggerOnce={true}
                    keyframes={rollLeftAnime}
                    className="absolute w-full h-full zIndex5"
                  >
                    <img
                      src={about01}
                      alt=""
                      className="letter_anime2 aboutPic absolute about01"
                    />
                  </Roll>
                  <Roll
                    direction="right"
                    duration="2500"
                    delay="50"
                    triggerOnce={true}
                    keyframes={rollRightAnime}
                    className="absolute w-full h-full zIndex3"
                  >
                    <img
                      src={about06}
                      alt=""
                      className="letter_anime3 aboutPic absolute about06"
                    />
                  </Roll>
                  <Roll
                    direction="right"
                    duration="2600"
                    delay="0"
                    triggerOnce={true}
                    keyframes={rollLeftAnime}
                    className="absolute w-full h-full"
                  >
                    <img
                      src={about05}
                      alt=""
                      className="letter_anime3 aboutPic absolute about05"
                    />
                  </Roll>
                  <Roll
                    direction="right"
                    duration="3000"
                    delay="0"
                    triggerOnce={true}
                    keyframes={rollRightAnime}
                    className="absolute w-full h-full zIndex3"
                  >
                    <img
                      src={about02}
                      alt=""
                      className="letter_anime aboutPic absolute about02"
                    />
                  </Roll>
                  <Roll
                    direction="right"
                    duration="2000"
                    delay="0"
                    triggerOnce={true}
                    keyframes={rollLeftAnime}
                    className="absolute w-full h-full zIndex6"
                  >
                    <img
                      src={about03}
                      alt=""
                      className="letter_anime2 aboutPic absolute about03"
                    />
                  </Roll>
                </div>
              </div>
              <div className="content flex column justify-center w-full h-full relative">
                <div className="title delaney-regular relative">
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <text x="0%" y="95%">
                      About
                    </text>
                  </svg>
                </div>
                <div className="front">
                  {
                    <div className="textArea">
                      <div className="firstArea">
                        <div className="mr-b6">
                          <BlockRevealAnimation
                            delay={2.1}
                            duration={0.7}
                            color="#f53c7c"
                          >
                            <span className="manrope aboutText">
                              <span>アイドルたちの、</span>
                            </span>
                          </BlockRevealAnimation>
                        </div>
                        <div>
                          <BlockRevealAnimation
                            delay={2.3}
                            duration={0.6}
                            color="#f53c7c"
                          >
                            <span className="manrope aboutText">
                              <span>素顔に迫る。</span>
                            </span>
                          </BlockRevealAnimation>
                        </div>
                      </div>
                      <div className="secondArea">
                        <BlockRevealAnimation
                          delay={2}
                          duration={1.8}
                          color="#f53c7c"
                        >
                          <span className="delaney-regular aboutText">
                            <span>The daily lives of idols.</span>
                          </span>
                        </BlockRevealAnimation>
                      </div>
                      <div className="thirdArea">
                        <BlockRevealAnimation
                          delay={2}
                          duration={1.9}
                          color="#f53c7c"
                        >
                          <div className="aboutText">
                            <span className="manrope">
                              アイドルグループ「○○」の10,000種類の自撮り画像。プライベートからお仕事現場自撮り姿をお届けします。アイドルグループ「○○」の10,000種類の自撮り画像。プライベらお仕事現場まで、様々な自撮り姿をお届けします。アイドルグループ「○○」の10,0類撮り画像ライベートからお仕事現場まで、様々な自撮り姿をお届けします。からお仕事現場まで、
                            </span>
                          </div>
                        </BlockRevealAnimation>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    );
  }
};

export default AboutPage;
