import BlockRevealAnimation from "react-block-reveal-animation";
import { Fade } from "react-awesome-reveal";
import Slider from "react-slick";
import { useInView } from "react-intersection-observer";
//slick画像
import slick01 from "../images/slick01.webp";
import slick02 from "../images/slick02.webp";
import slick03 from "../images/slick03.webp";
import slick04 from "../images/slick04.webp";
import slick05 from "../images/slick05.webp";
import slick06 from "../images/slick06.webp";
import slick07 from "../images/slick07.webp";
import slick08 from "../images/slick08.webp";
import slick09 from "../images/slick09.webp";
import slick10 from "../images/slick10.webp";
// letter画像
import letter04 from "../images/letter04.png";
import letter05 from "../images/letter05.png";
import item12 from "../images/item12.png";
import item13 from "../images/item13.png";
import item14 from "../images/item14.png";
import i_heart_blue from "../images/i_heart_blue.png";
import i_heart_blue2 from "../images/i_heart_blue2.png";
import i_heart_blue3 from "../images/i_heart_blue3.png";
import i_heart_blue4 from "../images/i_heart_blue4.png";
import i_star_blue from "../images/i_star_blue.png";
import i_star_blue2 from "../images/i_star_blue2.png";
import i_star_blue3 from "../images/i_star_blue3.png";

const MintPage = ({ scrollMintRef, useWindowWidth, bgColor }) => {
  const windowWidth = useWindowWidth();
  const { ref, inView, entry } = useInView({
    /* Optional options */
    rootMargin: "-100px", // ref要素が現れてから10px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
    threshold: 0,
  });

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0, //次の自動スクロールまでの時間
    pauseOnHover: false,
    cssEase: "linear",
  };
  if (windowWidth > 496) {
    return (
      <section id="MintPage" className={`w-full h-full ${bgColor === "MintPage" ? "MintBg" : ""}`} ref={ref}>
        <div className="w-full h-full" ref={scrollMintRef}>
          {inView && (
            <div className="mintContainer">
              <div className="title delaney-regular">
                <svg xmlns="http://www.w3.org/2000/svg">
                  <text x="0%" y="95%">
                    Sell
                  </text>
                </svg>
              </div>
              <div className="mintContent flex">
                <div className="front">
                  <div className="textArea">
                    <div className="firstArea">
                      <div>
                        <BlockRevealAnimation
                          delay={0.2}
                          duration={1.1}
                          color="#3f3a9c"
                        >
                          <span className="manrope mintText">
                            <span>彼女たちの、</span>
                          </span>
                        </BlockRevealAnimation>
                      </div>
                      <div>
                        <BlockRevealAnimation
                          delay={0.3}
                          duration={1}
                          color="#3f3a9c"
                        >
                          <span className="manrope mintText">
                            <span>日常の姿に触れる。</span>
                          </span>
                        </BlockRevealAnimation>
                      </div>
                    </div>
                    <div className="secondArea">
                      <BlockRevealAnimation
                        delay={0.5}
                        duration={1}
                        color="#3f3a9c"
                      >
                        <span className="delaney-regular mintText">
                          <span>We want you to see more of us.</span>
                        </span>
                      </BlockRevealAnimation>
                    </div>
                  </div>
                </div>
                <div className="MintRightArea">
                  <Fade
                    direction="right"
                    duration="2000"
                    delay="100"
                    triggerOnce={true}
                  >
                    <p>
                      {/* アイドルグループ「○○」の10,000種類の自撮り画像。プライベートからお仕事現場まで、様々な自撮り */}
                    </p>
                    <div className="mintInfo flex column">
                      <div className="information">
                        <div className="flex align-center justify-between">
                          <span>発行枚数</span>
                          <span>Coming Soon</span>
                        </div>
                      </div>
                      <div className="information">
                        <div className="flex align-center justify-between">
                          <span>価格</span>
                          <span>Coming Soon</span>
                        </div>
                      </div>
                      <div className="information">
                        <div className="flex align-center justify-between">
                          <span>ネットワーク</span>
                          <span>Bitcoin</span>
                        </div>
                      </div>
                    </div>
                    <div className="center-text">
                      <button disabled className="uppercase mintBtn">
                        Coming soon
                      </button>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex column w-full h-full">
                <div className="slickContainer sc01">
                  <Slider {...settings}>
                    <div className="slick-parent-div">
                      <img src={slick01} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick02} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick03} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick04} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick05} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick01} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick02} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick03} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick04} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick05} className="slickPic" alt="" />
                    </div>
                  </Slider>
                </div>
                <div className="slickContainer sc02">
                  <Slider {...settings} rtl={true}>
                    <div className="slick-parent-div">
                      <img src={slick06} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick07} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick08} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick09} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick10} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick06} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick07} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick08} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick09} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick10} className="slickPic" alt="" />
                    </div>
                  </Slider>
                </div>
              </div>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="letter04 absolute fit-content"
              >
                <img src={letter04} alt="" className="w-full letter_anime" />
              </Fade>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="item12 absolute fit-content"
              >
                <img src={item12} alt="" className="w-full letter_anime3" />
              </Fade>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="letter05 absolute fit-content"
              >
                <img src={letter05} alt="" className="w-full letter_anime2" />
              </Fade>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="item13 absolute fit-content"
              >
                <img src={item13} alt="" className="w-full letter_anime3" />
              </Fade>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="item14 absolute fit-content"
              >
                <img src={item14} alt="" className="w-full letter_anime2" />
              </Fade>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="i16 absolute fit-content"
              >
                <img src={i_star_blue} alt="" className="w-full letter_anime2" />
              </Fade>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="i17 absolute fit-content"
              >
                <img src={i_star_blue2} alt="" className="w-full letter_anime3" />
              </Fade>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="i18 absolute fit-content"
              >
                <img src={i_star_blue3} alt="" className="w-full letter_anime2" />
              </Fade>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="i19 absolute fit-content"
              >
                <img src={i_heart_blue} alt="" className="w-full letter_anime" />
              </Fade>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="i20 absolute fit-content"
              >
                <img src={i_heart_blue2} alt="" className="w-full letter_anime2" />
              </Fade>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="i21 absolute fit-content"
              >
                <img src={i_heart_blue3} alt="" className="w-full letter_anime3" />
              </Fade>
              <Fade
                duration="2000"
                delay="250"
                triggerOnce={true}
                className="i22 absolute fit-content"
              >
                <img src={i_heart_blue4} alt="" className="w-full letter_anime2" />
              </Fade>
            </div>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section className="SPMintPage" ref={ref}>
        <div className={`w-full h-full ${bgColor === "MintPage" ? "MintBg" : ""}`} ref={scrollMintRef}>
          {inView && (
            <div className="SPMintContainer">
              <div className="content flex column">
                <div className="title delaney-regular">
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <text x="0%" y="95%">
                      Sell
                    </text>
                  </svg>
                </div>
                <div className="flex">
                  <div className="front">
                    <div className="textArea">
                      <div className="firstArea">
                        <div className="mr-b6">
                          <BlockRevealAnimation
                            delay={0.2}
                            duration={1.1}
                            color="#3f3a9c"
                          >
                            <span className="manrope mintText">
                              <span>彼女たちの、</span>
                            </span>
                          </BlockRevealAnimation>
                        </div>
                        <div>
                          <BlockRevealAnimation
                            delay={0.3}
                            duration={1}
                            color="#3f3a9c"
                          >
                            <span className="manrope mintText">
                              <span>日常の姿に触れる。</span>
                            </span>
                          </BlockRevealAnimation>
                        </div>
                      </div>
                      <div className="secondArea">
                        <BlockRevealAnimation
                          delay={0.5}
                          duration={1}
                          color="#3f3a9c"
                        >
                          <span className="delaney-regular mintText">
                            <span>We want you to see more of us.</span>
                          </span>
                        </BlockRevealAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mintContent flex column gap10vw">
                <div className="text">
                  <span>
                    {/* アイドルグループ「○○」の10,000種類の自撮り画像。プライベートからお仕事現場まで、様々な自撮り */}
                  </span>
                </div>
                <div className="information flex column gap10vw">
                  <div className="details flex align-center justify-between">
                    <div className="left">
                      <span>発行枚数</span>
                    </div>
                    <div className="right">
                      <span>Coming&nbsp;Soon</span>
                    </div>
                  </div>
                  <div className="details flex align-center justify-between">
                    <div className="left">
                      <span>価格</span>
                    </div>
                    <div className="right">
                      <span>Coming&nbsp;Soon</span>
                    </div>
                  </div>
                  <div className="details flex align-center justify-between">
                    <div className="left">
                      <span>ネットワーク</span>
                    </div>
                    <div className="right">
                      <span>Ethereumメインネット</span>
                    </div>
                  </div>
                </div>
                <div style={{textAlign:'center'}}>
                  <button disabled className="uppercase mintBtn">Coming soon</button>
                </div>
              </div>
              <div className="flex column w-full h-full">
                <div className="slickContainer sc01">
                  <Slider {...settings}>
                    <div className="slick-parent-div">
                      <img src={slick01} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick02} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick03} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick04} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick05} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick01} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick02} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick03} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick04} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick05} className="slickPic" alt="" />
                    </div>
                  </Slider>
                </div>
                <div className="slickContainer sc02">
                  <Slider {...settings} rtl={true}>
                    <div className="slick-parent-div">
                      <img src={slick06} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick07} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick08} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick09} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick10} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick06} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick07} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick08} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick09} className="slickPic" alt="" />
                    </div>
                    <div>
                      <img src={slick10} className="slickPic" alt="" />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
};

export default MintPage;
