import { useState } from "react";
import { Fade, Roll } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import BlockRevealAnimation from "react-block-reveal-animation";
import { useInView } from "react-intersection-observer";

import item08 from "../images/item08.png";
import item09 from "../images/item09.png";
import item10 from "../images/item10.png";
import number01 from "../images/01.png";
import number02 from "../images/02.png";
import number03 from "../images/03.png";
import number04 from "../images/04.png";
// 文字画像
import letter10 from "../images/letter10.png";
import letter11 from "../images/letter11.png";
import letter12 from "../images/letter12.png";
// アイコン画像
import i_heart_lightblue from "../images/i_heart_lightblue.png";
import i_heart_lightblue2 from "../images/i_heart_lightblue2.png";
import i_heart_lightblue3 from "../images/i_heart_lightblue3.png";
import i_star_lightblue from "../images/i_star_lightblue.png";


export const Question = ({ SPskew, skew, number, question, answer }) => {
  return (
    <div
      className={`QuestionBox flex column gap-20 relative ${
        skew ? "letter_anime_skew3" : "letter_anime_skew4"
      } ${SPskew === "left" && "letter_anime_skew5"} ${
        SPskew === "right" && "letter_anime_skew6"
      }`}
    >
      <div className="QATitle">
        <h2>{question}</h2>
      </div>
      <div className="black">
        <p>
          {answer}
        </p>
      </div>
      <div className="absolute numberPosition">
        {number === "one" && <img src={number01} alt="" className="number" />}
        {number === "two" && <img src={number02} alt="" className="number" />}
        {number === "three" && <img src={number03} alt="" className="number" />}
        {number === "four" && <img src={number04} alt="" className="number" />}
      </div>
    </div>
  );
};

const QAPage = (props) => {
  const [skew, setSkew] = useState(true);
  const windowWidth = props.useWindowWidth();
  const { ref, inView, entry } = useInView({
    /* Optional options */
    rootMargin: "-100px", // ref要素が現れてから10px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
    threshold: 0,
  });

  const SlideRight1Anime = keyframes`
    from {
      transform: translateX(70%) rotate(-20deg);
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    to {
      transform: translate(-2%, -10%) rotate(0deg);
      opacity: 1;
    }
  `;
  const SlideRight2Anime = keyframes`
    from {
      transform: translateX(100%) rotate(45deg);
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    to {
      transform: translate(0, 10%) rotate(0deg);
      opacity: 1;
    }
  `;
  const SlideLeft1Anime = keyframes`
    0% {
      transform: translate(-100%, 0%) rotate(-30deg);
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      transform: translate(3%, 8%) rotate(3deg);
      opacity: 1;
    }
  `;
  const SlideLeft2Anime = keyframes`
    0% {
      transform: translate(-100%, 0%) rotate(-45deg);
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      transform: translate(0, 10%) rotate(-2deg);
      opacity: 1;
    }
  `;

  if (windowWidth > 496) {
    return (
      <section id="QAPage" className={`relative w-full ${props.bgColor === "QAPage" ? "QABg" : ""}`} ref={ref}>
        <div ref={props.scrollQAPageRef}>
          {inView && (
            <>
              <div className="title delaney-regular">
                <svg xmlns="http://www.w3.org/2000/svg">
                  <text x="0%" y="71%">
                    What's Selfies
                  </text>
                </svg>
              </div>
              {/* <div className="textArea">
                <div className="secondArea">
                  <div className="manrope QAText1">
                    <BlockRevealAnimation
                      delay={0.2}
                      duration={1.1}
                      color="#24A4DE"
                    >
                      <span className="QAText">
                        <span>
                        web3での経験豊富なチームと
                        </span>
                      </span>
                    </BlockRevealAnimation>
                  </div>
                </div>
                <div className="secondArea">
                  <div className="manrope QAText2">
                    <BlockRevealAnimation
                      delay={0.3}
                      duration={1.1}
                      color="#24A4DE"
                    >
                      <span className="QAText">
                        <span>
                          朝日放送グループのアニメ製作会社がタッグを組んで盛り上げる！
                        </span>
                      </span>
                    </BlockRevealAnimation>
                  </div>
                </div>
              </div> */}
            </>
          )}
          <div className="QAArea flex w-full">
            {/* 01 */}

            <span className="QAContainer relative">
              <Roll
                direction="right"
                duration="2100"
                delay="0"
                triggerOnce={true}
                keyframes={SlideLeft1Anime}
                className="w-full h-full absolute"
              >
                <span className="imageShadow absolute letter_anime_skew4"></span>
              </Roll>
              <Roll
                direction="right"
                duration="2100"
                delay="50"
                triggerOnce={true}
                keyframes={SlideLeft1Anime}
              >
                <Question 
                  skew={false}
                  number={"one"}
                  question='Seliesとはなんですか？'
                  answer={<>まだ無名な10人のガールズポップスユニットが認知拡大のために自撮り画像をNFTで配布するプロジェクトです。<br />ホルダーをプロデューサーと呼び、まだ無名なアイドルをプロデューサーたちが夢のステージに立たせるボトムアッププロジェクトでもあります。</>} />
              </Roll>
            </span>

            {/* 02 */}

            <span className="QAContainer relative">
              <Roll
                direction="right"
                duration="1900"
                delay="0"
                triggerOnce={true}
                keyframes={SlideLeft1Anime}
                className="w-full h-full absolute"
              >
                <span className="imageShadow2 absolute letter_anime_skew3"></span>
              </Roll>
              <Roll
                direction="right"
                duration="2000"
                delay="60"
                triggerOnce={true}
                keyframes={SlideRight1Anime}
              >
                <Question 
                  skew={true}
                  number={"two"}
                  question='特徴はなんですか？'
                  answer={<>累計NFT販売3億円を実績とし、NFT初のTVアニメ化も実現したweb3チームのTHE BATTLEと、国民的TVアニメ『プリキュア』シリーズの製作にも携わるアニメ会社・ABCアニメーションが共同で運営している部分です。<br />アイドルのジェネラティブパーツも合計1兆通りを超え、Selfiesも将来的なアニメ化を目標としています。</>} />
              </Roll>
            </span>

            {/* 03 */}

            <span className="QAContainer relative">
              <Roll
                direction="right"
                duration="1900"
                delay="0"
                triggerOnce={true}
                keyframes={SlideLeft1Anime}
                className="w-full h-full absolute"
              >
                <span className="imageShadow3 absolute letter_anime_skew3"></span>
              </Roll>
              <Roll
                direction="right"
                duration="1900"
                delay="65"
                triggerOnce={true}
                keyframes={SlideLeft2Anime}
              >
                <Question 
                  skew={true}
                  number={"three"}
                  question='特徴はなんですか？'
                  answer={<>GEN1では一期生の10人が、自分の自撮り（セルフィー）をプロデューサーに見てもらいます。<br />ロードマップにもあるように時間の経過とともに第二期生以降もがGEN2で発表されます。<br />GEN2はGEN1のプロデューサーにも楽しめるような仕掛けを用意する予定です。</>} />
              </Roll>
            </span>

            {/* 04 */}

            <span className="QAContainer relative">
              <Roll
                direction="right"
                duration="2000"
                delay="0"
                triggerOnce={true}
                keyframes={SlideLeft1Anime}
                className="w-full h-full absolute"
              >
                <span className="imageShadow4 absolute letter_anime_skew4"></span>
              </Roll>
              <Roll
                direction="right"
                duration="2000"
                delay="55"
                triggerOnce={true}
                keyframes={SlideRight2Anime}
              >
                <Question 
                  skew={true}
                  SPskew={true}
                  number={"four"}
                  question='なぜアイドルとアニメ'
                  answer={<>日本のアニメ制作スタイルとアイドル現場は古く、Web3の力で変えることが求められています。<br />またCOVIDによるライブ規制などもあり、アイドルの活動範囲も制限されています。<br />このプロジェクトではこの問題解決に取り組んでいます。</>} />
              </Roll>
            </span>
            {/* <span className="QAContainer relative">
            <Roll
              direction="right"
              duration="1000"
              delay="0"
              triggerOnce={true}
              keyframes={SlideLeft1Anime}
            >
              <Question skew={false} number={"one"} />
            </Roll>
          </span>
          <span className="QAContainer">
            <Roll
              direction="right"
              duration="2000"
              delay="10"
              triggerOnce={true}
              keyframes={SlideRight1Anime}
            >
              <Question skew={true} number={"two"} />
            </Roll>
          </span>
          <span className="QAContainer">
            <Roll
              direction="right"
              duration="1600"
              delay="15"
              triggerOnce={true}
              keyframes={SlideLeft2Anime}
            >
              <Question skew={true} number={"three"} />
            </Roll>
          </span>
          <span className="QAContainer">
            <Roll
              direction="right"
              duration="2400"
              delay="5"
              triggerOnce={true}
              keyframes={SlideRight2Anime}
            >
              <Question skew={false} number={"four"} />
            </Roll>
          </span> */}
          </div>
        </div>
        <Fade
          duration="2000"
          delay="250"
          triggerOnce={true}
          className="item08 absolute fit-content"
        >
          <img src={item08} alt="" className="w-full letter_anime" />
        </Fade>
        <Fade
          duration="2000"
          delay="250"
          triggerOnce={true}
          className="item09 absolute fit-content"
        >
          <img src={item09} alt="" className="w-full letter_anime2" />
        </Fade>
        <Fade
          duration="2000"
          delay="250"
          triggerOnce={true}
          className="letter10 absolute fit-content"
        >
          <img src={letter10} alt="" className="w-full letter_anime3" />
        </Fade>
        <Fade
          duration="2000"
          delay="250"
          triggerOnce={true}
          className="letter11 absolute fit-content"
        >
          <img src={letter11} alt="" className="w-full letter_anime2" />
        </Fade>
        <Fade
          duration="2000"
          delay="100"
          triggerOnce={true}
          className="letter12 absolute fit-content"
        >
          <img src={letter12} alt="" className="w-full letter_anime3" />
        </Fade>
        <Fade
          duration="2000"
          delay="100"
          triggerOnce={true}
          className="item10 absolute fit-content"
        >
          <img src={item10} alt="" className="w-full letter_anime" />
        </Fade>
        <Fade
          duration="2000"
          delay="100"
          triggerOnce={true}
          className="i39 absolute fit-content"
        >
          <img src={i_heart_lightblue} alt="" className="w-full letter_anime2" />
        </Fade>
        <Fade
          duration="2000"
          delay="100"
          triggerOnce={true}
          className="i40 absolute fit-content"
        >
          <img src={i_heart_lightblue2} alt="" className="w-full letter_anime3" />
        </Fade>
        <Fade
          duration="2000"
          delay="100"
          triggerOnce={true}
          className="i41 absolute fit-content"
        >
          <img src={i_heart_lightblue3} alt="" className="w-full letter_anime" />
        </Fade>
        <Fade
          duration="2000"
          delay="100"
          triggerOnce={true}
          className="i42 absolute fit-content"
        >
          <img src={i_star_lightblue} alt="" className="w-full letter_anime2" />
        </Fade>
      </section>
    );
  } else {
    return (
      <section className={`SPQAPage SP relative w-full ${props.bgColor === "QAPage" ? "QABg" : ""}`} ref={ref}>
        <div className="relative w-full h-full" ref={props.scrollQAPageRef}>
          {inView && (
            <>
              <div className="titleContanier">
                <div className="title delaney-regular">
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <text x="0%" y="71%" className="w-70">
                      What's
                    </text>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className="transY">
                    <text x="0%" y="31%" className="w-70">
                      Selfies
                    </text>
                  </svg>
                </div>
                {/* <div className="textArea">
                  <div className="secondArea">
                    <div className="manrope">
                      <BlockRevealAnimation
                        delay={0.2}
                        duration={1.1}
                        color="#24A4DE"
                      >
                        <span className="QAText">
                          <span>アイドルグループ「〇〇」の10,000種類の</span>
                        </span>
                      </BlockRevealAnimation>
                    </div>
                  </div>
                  <div className="secondArea">
                    <div className="manrope">
                      <BlockRevealAnimation
                        delay={0.2}
                        duration={1.1}
                        color="#24A4DE"
                      >
                        <span className="QAText">
                          <span>自撮り画像</span>
                        </span>
                      </BlockRevealAnimation>
                    </div>
                  </div>
                  <div className="secondArea">
                    <div className="manrope">
                      <BlockRevealAnimation
                        delay={0.3}
                        duration={1.1}
                        color="#24A4DE"
                      >
                        <span className="QAText">
                          <span>プライベートからお仕事現場まで、</span>
                        </span>
                      </BlockRevealAnimation>
                    </div>
                  </div>
                  <div className="secondArea">
                    <div className="manrope">
                      <BlockRevealAnimation
                        delay={0.3}
                        duration={1.1}
                        color="#24A4DE"
                      >
                        <span className="QAText">
                          <span>様々な自撮り姿をお届けします。</span>
                        </span>
                      </BlockRevealAnimation>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="SPQAContainer flex column">
                <Question 
                  number={"one"}
                  SPskew="left"
                  question='Seliesとはなんですか？'
                  answer={<>まだ無名な10人のガールズポップスユニットが認知拡大のために自撮り画像をNFTで配布するプロジェクトです。<br />ホルダーをプロデューサーと呼び、まだ無名なアイドルをプロデューサーたちが夢のステージに立たせるボトムアッププロジェクトでもあります。</>} />
                <Question 
                  SPskew="right"
                  number={"two"}
                  question='特徴はなんですか？'
                  answer={<>累計NFT販売3億円を実績とし、NFT初のTVアニメ化も実現したweb3チームのTHE BATTLEと、国民的TVアニメ『プリキュア』シリーズの製作にも携わるアニメ会社・ABCアニメーションが共同で運営している部分です。<br />アイドルのジェネラティブパーツも合計1兆通りを超え、Selfiesも将来的なアニメ化を目標としています。</>} />
                <Question 
                  SPskew="left"
                  number={"three"}
                  question='特徴はなんですか？'
                  answer={<>GEN1では一期生の10人が、自分の自撮り（セルフィー）をプロデューサーに見てもらいます。<br />ロードマップにもあるように時間の経過とともに第二期生以降もがGEN2で発表されます。<br />GEN2はGEN1のプロデューサーにも楽しめるような仕掛けを用意する予定です。</>} />
                <Question 
                  SPskew="left"
                  number={"four"}
                  question='なぜアイドルとアニメ'
                  answer={<>日本のアニメ制作スタイルとアイドル現場は古く、Web3の力で変えることが求められています。<br />またCOVIDによるライブ規制などもあり、アイドルの活動範囲も制限されています。<br />このプロジェクトではこの問題解決に取り組んでいます。</>} />
              </div>
            </>
          )}
        </div>
      </section>
    );
  }
};

export default QAPage;
